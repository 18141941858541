import * as d3 from 'd3';
import d3Tip from 'd3-tip';

export default class ShiftRenderer {
    constructor(canvas, statisticsUpdateCallback) {
        this.canvas = canvas;
        this.transitionDuration = 500;
        this.dragBarWidth = 6;
        this.minShiftIntervalMinutes = 30;
        this.maxShiftIntervalMinutes = 16*60;
        this.verticalPadding = 0.05;
        this.textPadding = 8;
        this.statisticsUpdateCallback = statisticsUpdateCallback;


        const formatTime = d3.timeFormat('%H:%M');
        this.shiftTimesTip = d3Tip().attr('class', 'd3-tip').offset([-10, 0]).html(function(d) {
            return '<p>' + formatTime(d.localShift.startTime) + ' - ' + formatTime(d.localShift.endTime) + '</p>';
        });

        /* Invoke the tip in the context of your visualization */
        this.canvas.shiftChart.call(this.shiftTimesTip);
    }

    render(animate = false) {
        //Order shifts
        this.orderShifts();

        //Plot shifts
        var bars = this.canvas.shiftChart.selectAll('g.barGroup').data(
            this.canvas.shifts,
            function(d) { return d.type + d.localShift.shiftId; } //Determines id of each element
        ); //See https://bost.ocks.org/mike/join/

        //New shifts: blocks
        var newBars = bars.enter().append('g').attr('class','barGroup');
        this.addContentsToBarGroups(newBars);

        //Existing shifts: animate to new position
        this.layOutBarGroups(bars, animate);

        //Removed shifts
        this.removeBarGroups(bars.exit(), animate);
    }

    orderShifts() {
        this.canvas.shifts.sort(function(a, b) {
            /*
            Order on shiftTime descending.
            Note: If compareFunction(a, b) is less than 0, sort a to a lower index than b, i.e. a comes first.
            */
            if (b.localShift.shiftTime - a.localShift.shiftTime != 0) {
                return (b.localShift.shiftTime - a.localShift.shiftTime);
            }
            return b.localShift.shiftId - a.localShift.shiftId;
        });

        var maxLevel = 1;
        for (var i=0; i < this.canvas.shifts.length; i++) {
            //Generate array of available levels
            var availableLevels = [];
            for (var j=1; j <= maxLevel + 1; j++) {
                availableLevels.push(j);
            }

            //Loop through shifts before this shifts and remove elements from available levels that are alredy taken
            for (var k=0; k < i; k++) {
                if (this.canvas.shifts[k].localShift.startTime < this.canvas.shifts[i].localShift.endTime && this.canvas.shifts[k].localShift.endTime > this.canvas.shifts[i].localShift.startTime) {
                    //There is overlap
                    var index = availableLevels.indexOf(this.canvas.shifts[k].level);
                    if (index > -1) {
                        availableLevels.splice(index, 1);
                    }
                }
            }

            this.canvas.shifts[i].level = Math.min.apply(null, availableLevels);
            maxLevel = Math.max(maxLevel,this.canvas.shifts[i].level);
        }
    }

    addContentsToBarGroups(barGroups) {
        barGroups.append('rect')
        .attr('class', 'bar')
        .attr('clip-path', 'url(#clip)')
        .attr('rx',1)
        .attr('ry',1)
        .on('click', function(d) {
            if (d3.event.altKey) {
                _this.canvas.removeShiftCallback(d, () => {
                    _this.canvas.shifts = _this.canvas.shifts.filter(function(l) {
                        return !(l.localShift.shiftId == d.localShift.shiftId && l.type == d.type);
                    });
                    _this.canvas.render();
                });
            } else {
                //Show settings
                //let shift = _this.canvas.shifts.find((v) => v.localShift.shiftId == d.localShift.shiftId && v.type == d.type);
                _this.canvas.showModalCallback(d);
            }
        })
        .on('mouseover', function(d) {
            _this.shiftTimesTip.show(d, this);
        })
        .on('mouseout', this.shiftTimesTip.hide)
        .call(d3.drag()
            .subject(function () { //To ensure dragging aligns mouse position in object with dragging of object
                var t = d3.select(this);
                return {x: t.attr('x'), y: t.attr('y') };
            })
            .on('start', function (d) {
                //d3.select(this.parentNode).selectAll('text.label').text('');
                _this.shiftTimesTip.show(d, this);
            })
            .on('drag', function (d) {
                let snappedCursorX = _this.canvas.xScaleSnapped(d3.event.x);
                var intervalMilliSeconds = d.localShift.shiftTime;
                d.localShift.startTime = _this.canvas.xScale.invert(snappedCursorX); //Cursor position
                d.localShift.endTime = d3.timeMillisecond.offset(d.localShift.startTime, intervalMilliSeconds);
                _this.render(false);
                _this.shiftTimesTip.show(d, this);
            })
            .on('end', (d) => {
                //This is also triggered on click. Only make changes if really dragged
                if (Math.abs(d3.event.dx) > 0) {
                    let snappedCursorX = _this.canvas.xScaleSnapped(d3.event.x);
                    var intervalMilliSeconds = d.localShift.shiftTime;
                    d.localShift.startTime = this.canvas.xScale.invert(snappedCursorX); //Cursor position
                    d.localShift.endTime = d3.timeMillisecond.offset(d.localShift.startTime, intervalMilliSeconds);
                    this.render(true);
                    this.statisticsUpdateCallback();
                    this.shiftTimesTip.hide(d);
                }
            })
        );

        var _this = this;
        barGroups.append('rect')
        .attr('class','bar-handle left')
        .attr('width', this.dragBarWidth)
        .attr('clip-path', 'url(#clip)')
        .on('mouseover', function(d) {
            _this.shiftTimesTip.show(d, this);
        })
        .on('mouseout', this.shiftTimesTip.hide)
        .call(d3.drag()
            .subject(function () { //To ensure dragging aligns mouse position in object with dragging of object
                var t = d3.select(this);
                return {x: t.attr('x'), y: t.attr('y') };
            })
            .on('start', function (d) {
                _this.shiftTimesTip.show(d, this);
            })
            .on('drag', function (d) {
                let snappedCursorX = _this.canvas.xScaleSnapped(d3.event.x);
                d.localShift.startTime = new Date(
                    Math.max(
                        Math.min(
                            _this.canvas.xScale.invert(snappedCursorX), //Cursor position
                            d3.timeMinute.offset(d.localShift.endTime, -1 * _this.minShiftIntervalMinutes) //Ensuring shift will be at least as long as minimum shift length
                        ),
                        d3.timeMinute.offset(d.localShift.endTime, -1 * _this.maxShiftIntervalMinutes) //Ensuring shift will be at most as long as maximum shift length
                    )
                );
                _this.render(false);
                _this.shiftTimesTip.show(d, this);
            })
            .on('end', (d) => {
                let snappedCursorX = _this.canvas.xScaleSnapped(d3.event.x);
                d.localShift.startTime = new Date(
                    Math.max(
                        Math.min(
                            this.canvas.xScale.invert(snappedCursorX), //Cursor position
                            d3.timeMinute.offset(d.localShift.endTime, -1 * this.minShiftIntervalMinutes) //Ensuring shift will be at least as long as minimum shift length
                        ),
                        d3.timeMinute.offset(d.localShift.endTime, -1 * this.maxShiftIntervalMinutes) //Ensuring shift will be at most as long as maximum shift length
                    )
                );
                this.render(true);
                this.statisticsUpdateCallback();
                this.shiftTimesTip.hide(d);
            })
        );

        barGroups.append('rect')
        .attr('class','bar-handle right')
        .attr('width', this.dragBarWidth)
        .attr('clip-path', 'url(#clip)')
        .on('mouseover', function(d) {
            _this.shiftTimesTip.show(d, this);
        })
        .on('mouseout', this.shiftTimesTip.hide)
        .call(d3.drag()
            .subject(function () { //To ensure dragging aligns mouse position in object with dragging of object
                var t = d3.select(this);
                return {x: t.attr('x'), y: t.attr('y') };
            })
            .on('start', function (d) {
                _this.shiftTimesTip.show(d, this);
            })
            .on('drag', function (d) {
                let snappedCursorX = _this.canvas.xScaleSnapped(d3.event.x);
                d.localShift.endTime = new Date(
                    Math.min(
                        Math.max(
                            _this.canvas.xScale.invert(snappedCursorX), //Cursor position
                            d3.timeMinute.offset(d.localShift.startTime, _this.minShiftIntervalMinutes) //Ensuring shift will be at least as long as minimum shift length
                        ),
                        d3.timeMinute.offset(d.localShift.startTime, _this.maxShiftIntervalMinutes) //Ensuring shift will be at most as long as maximum shift length
                    )
                );
                _this.render(false);
                _this.shiftTimesTip.show(d, this);
            })
            .on('end', (d) => {
                let snappedCursorX = _this.canvas.xScaleSnapped(d3.event.x);
                d.localShift.endTime = new Date(
                    Math.min(
                        Math.max(
                            this.canvas.xScale.invert(snappedCursorX), //Cursor position
                            d3.timeMinute.offset(d.localShift.startTime, this.minShiftIntervalMinutes) //Ensuring shift will be at least as long as minimum shift length
                        ),
                        d3.timeMinute.offset(d.localShift.startTime, this.maxShiftIntervalMinutes) //Ensuring shift will be at most as long as maximum shift length
                    )
                );
                this.render(true);
                this.statisticsUpdateCallback();
                this.shiftTimesTip.hide(d);
            })
        );

        barGroups.append('text')
        .attr('clip-path', 'url(#clip)')
        .attr('class', 'label')
        .attr('dy', '.35em'); //vertical align middle

        this.layOutBarGroups(barGroups, false);
    }

    layOutBarGroups(barGroups, animate = false) {
        var _this = this;

        var x = (d) => { //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Functions/Arrow_functions
            return this.canvas.xScale(d.localShift.startTime);
        };

        var y = (d) => { //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Functions/Arrow_functions
            return this.canvas.yScaleForShifts(d.level) + this.verticalPadding;
        };

        var textY = (d) => {
            return y(d) + this.canvas.yScaleForShifts(0) - this.canvas.yScaleForShifts((1 - 2*this.verticalPadding) / 2);
        };

        var width = (d) => {
            var hstart = d.localShift.startTime;
            var hstop = d.localShift.endTime;
            return this.canvas.xScale(hstop)-this.canvas.xScale(hstart);
        };

        var text = function(d){
            if (d.localShift.driver != undefined) {
                return d.localShift.driver.name;
            }
            return 'To be determined';
        };

        var wrapText = function (element, width, padding) {
            var self = d3.select(element),
                textLength = self.node().getComputedTextLength(),
                text = self.text();
            while (textLength > (width - 2 * padding) && text.length > 0) {
                text = text.slice(0, -1);
                self.text(text + '...');
                textLength = self.node().getComputedTextLength();
            }
        };

        var bars = barGroups.selectAll('rect.bar');
        var barHandlesLeft = barGroups.selectAll('rect.bar-handle.left');
        var barHandlesRight = barGroups.selectAll('rect.bar-handle.right');
        var barText = barGroups.selectAll('text.label');
        var barTextTimeout = 0;
        var barTextTransition = barText;
        if (animate) {
            bars = bars.transition().duration(this.transitionDuration);
            barHandlesLeft = barHandlesLeft.transition().duration(this.transitionDuration);
            barHandlesRight = barHandlesRight.transition().duration(this.transitionDuration);
            barText.each(function(d) {
                var element = d3.select(this);
                if (Math.abs(parseFloat(element.attr('y'))-  textY(d)) > 0.001) {
                    element.text('');
                }
            });
            barTextTransition = barText.transition().duration(this.transitionDuration);
        }

        bars
        .attr('class',(d) => {
            let cssStatus = d.status().replace(/([A-Z])/g, (g) => `-${g[0].toLowerCase()}`); //Convert to CSS case
            return 'bar ' + cssStatus;
        })
        .attr('y',y)
        .attr('x',x)
        .attr('width', width)
        .attr('height',() => {
            return this.canvas.yScaleForShifts(0)-this.canvas.yScaleForShifts(1-2*this.verticalPadding);
        });

        barHandlesLeft
        .attr('x', (d) => { return x(d) - (this.dragBarWidth/2); })
        .attr('y', y)
        .attr('height',() => {
            return this.canvas.yScaleForShifts(0)-this.canvas.yScaleForShifts(1-2*this.verticalPadding);
        });

        barHandlesRight
        .attr('x', (d) => { return x(d) + width(d) - (this.dragBarWidth/2); })
        .attr('y', y)
        .attr('height',() => {
            return this.canvas.yScaleForShifts(0)-this.canvas.yScaleForShifts(1-2*this.verticalPadding);
        });

        barTextTransition
        .attr('x',(d) => {
            return x(d) + this.textPadding; //padding
        })
        .attr('y', textY)
        .attr('width', width);

        d3.timeout(() => {
            barText
            .text(text)
            .each(function(d) {
                return wrapText(this, width(d), _this.textPadding);
            });
        }, barTextTimeout);
    }

    removeBarGroups(barGroups, animate) {
        var bars = barGroups.selectAll('rect.bar');
        var barHandles = barGroups.selectAll('rect.bar-handle');
        var barText = barGroups.selectAll('text.label');
        var _barGroups = barGroups;

        if (animate) {
            bars = bars.transition().duration(this.transitionDuration);
            _barGroups = _barGroups.transition().duration(this.transitionDuration);

            //Don't animate removal of barText and barHandlers, remove them immediately
        }


        bars.attr('width', 0);

        barHandles.remove();

        barText.remove();

        _barGroups.remove();

    }
}
