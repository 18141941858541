/*global L, $*/

import * as AbelKitWebAdmin from 'abelkit-js';

class AutocompleteField {

    /**
     * @param inputElementId              {String}
     * @param placeSelectionCallback      {func}
     * @param bounds                      {array}
     * @param abelKit                     {AbelAdminKit}
     * @param completeFromMeetingPoints   {boolean}
     * @param completeFromLocationService {boolean}
     */
    constructor(inputElementId, placeSelectionCallback, bounds, abelKit,
        completeFromMeetingPoints = true, completeFromLocationService = true) {
        this.abelKit = abelKit;
        this.placesSelectionCallback = (data) => placeSelectionCallback(inputElementId, data);

        // meeting point cache
        this.meetingPoints = [];

        // set bounds
        this.bounds = new google.maps.LatLngBounds();
        bounds.map(coordinates => {
            const latLng = new google.maps.LatLng(coordinates.lat, coordinates.lng);
            this.bounds.extend(latLng);
        });

        // initialize autocomplete
        this.initPlacesAutocomplete(inputElementId, completeFromMeetingPoints, completeFromLocationService);
    }

    /**
     * Initialize the Google Places
     * auto complete search input
     *
     * @param inputElementId              {String}
     * @param completeFromMeetingPoints   {boolean}
     * @param completeFromLocationService {boolean}
     */
    initPlacesAutocomplete(inputElementId, completeFromMeetingPoints, completeFromLocationService) {
        // init service
        this.autocomplete = new google.maps.places.AutocompleteService();

        // get container for suggestions
        const suggestionsContainer = $(inputElementId).siblings('.suggestions').get(0);

        // show autocomplete list
        new autoComplete({
            suggestionsContainer,
            selector: inputElementId,
            minChars: 1,
            source: (term, suggest) => {
                new Promise(function (resolve, reject) {
                    resolve(this.getAutocompleteList(term, completeFromMeetingPoints, completeFromLocationService));
                }.bind(this)).then(
                    results => suggest(results)
                );
            },
            renderItem: this.renderSuggestion,
            onSelect: (e, term, item) => this.placeSelected(e, term, item),
        });
    }

    /**
     * Get a list of autocomplete items for
     * a specific search term.
     *
     * @param term
     * @param completeFromMeetingPoints   {boolean}
     * @param completeFromLocationService {boolean}
     *
     * @return {Promise}
     */
    getAutocompleteList(term, completeFromMeetingPoints, completeFromLocationService) {
        const results = [];

        return new Promise((resolve, reject) => {
            // get suggestions from meeting points
            if (completeFromMeetingPoints) {
                return this.abelKit.AbelAdminService()
                    .searchMeetingPoints(term)
                    .then((data) => {
                        const meetingPoints = [];

                        for (let i = 0; i < data.Hits.length; i++) {
                            meetingPoints.push({
                                type: 'map-marker',
                                name: data.Hits[i].name,
                                location: new AbelKitWebAdmin.Objects.AKLocationBase({
                                    locationType: 'gpscoord',
                                    geoType: 'wgs84',
                                    coordinate: {
                                        x: data.Hits[i].longitude,
                                        y: data.Hits[i].latitude
                                    },
                                    POIName: data.Hits[i].name
                                }),
                            });
                        }

                        // set meeting points in cache
                        this.meetingPoints = meetingPoints;

                        return meetingPoints;
                    })
                    .then((meetingPoints) => {
                        // add first 4 meetingPoints to result list
                        for (let index = 0; index < 4; ++index) {
                            const meetingPoint = meetingPoints[index];
                            if (meetingPoint == undefined) continue;
                            results.push(meetingPoint);
                        }

                        resolve(results);
                    })
                    .catch(error => console.warn(error));
            } else {
                resolve(results);
            }
        })
            .then((results) => {
                // get suggestions from Google Places API
                if (completeFromLocationService) {
                    // find matching places
                    this.autocomplete.getPlacePredictions(
                        {
                            input: term,
                            bounds: this.bounds,
                        },
                        (predictions, status) => {
                            if (status === 'OK') {
                                // add first 4 places results to result list
                                for (let index = 0; index < 4; ++index) {
                                    const placeResult = predictions[index];
                                    if (placeResult == undefined) continue;
                                    results.push(placeResult);
                                }
                            }
                        }
                    )
                }

                return results;
            });
    }

    /**
     * Render a single autocomplete suggestion.
     *
     * @param item
     * @param term
     */
    renderSuggestion(item, term) {
        const placeId = item.place_id || '';
        const name = item.name || '';

        const iconClass = placeId ? 'glyphicon glyphicon-globe' : 'glyphicon glyphicon-map-marker';

        return `<span class="autocomplete-suggestion" data-description="${item.description}" data-placeid="${placeId}" data-meetingpoint="${name}">
                    <i class="${ iconClass}"></i>
                    <strong>${ item.description || name}</strong>
                </span>`;
    }

    /**
     * Handler for a clicked autocomplete
     * list-item.
     *
     * @param e    {Object}
     * @param term {String}
     * @param item {Object}
     */
    placeSelected(e, term, item) {
        const placeId = item.dataset.placeid;
        const description = item.dataset.description;

        if (placeId.length > 0) {
            // google places search result
            this.onGooglePlaceClick(placeId, description);
        } else {
            // meeting point
            this.onMeetingPointClick(item);
        }
    }

    /**
     * Handle click on a 'Google Places'
     * autocomplete suggestion
     *
     * @param e {String}
     * @param placeId {String}
     * @param description {String}
     */
    onGooglePlaceClick(placeId, description = '') {
        const geocoder = new google.maps.Geocoder;

        geocoder.geocode({ 'placeId': placeId }, (results, status) => {
            if (status === 'OK') {
                if (results[0]) {
                    const geometry = results[0].geometry;

                    const locationData = {
                        POIName: description,
                        locationType: 'gpscoord',
                        geoType: 'wgs84',
                        coordinate: {
                            x: geometry.location.lat(),
                            y: geometry.location.lng(),
                        }
                    };

                    const data = new AbelKitWebAdmin.Objects.AKLocationBase(locationData);

                    this.placesSelectionCallback({ location: data });
                }
            }
        });
    }

    /**
     * Handle click on a meeting point
     * autocomplete suggestion, ie.
     * a bus stop
     *
     * @param item {Object}
     */
    onMeetingPointClick(item) {
        const name = item.dataset.meetingpoint;
        const meetingPoint = this.meetingPoints.find(meetingPoint => meetingPoint.name === name);

        this.placesSelectionCallback(meetingPoint);
    }
}

export default class Autocomplete {
    constructor(abelKit, inputElements = [], placeSelectionCallback,
        completeFromMeetingPoints = true, completeFromLocationService = true) {
        // get contract bounding box
        const boundingBox = abelKit._admin.get('contracts').getSettingForSelectedContract('mapBoundingBox');

        // create instance for each field
        inputElements.map(inputElement =>
            new AutocompleteField(inputElement, placeSelectionCallback, boundingBox, abelKit)
        );

        //Set defaults
        this.timeout = null;
    }

    queryAutocomplete(query, asyncResults) {
        if (this.completeFromMeetingPoints) {

        } else {
            this.callBack(asyncResults);
        }

    }

    callBack(asyncResults) {
        this.callBackCalled++;

        if (this.callBackCalled === 2) {
            asyncResults(
                this.meetingPointsResults.concat(this.locationSearchResults)
            );
        }
    }

}
