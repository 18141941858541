/*global $, Templates */
import * as AbelKitWebAdmin from "abelkit-js";
import Autocomplete from "./../Autocomplete/Autocomplete";

export default class Modal {
    constructor(
        shift,
        mapBoundingBox,
        vehicleTypes,
        modalClosedCallback,
        abelKit
    ) {
        //this.shift = shift;
        this.mapBoundingBox = mapBoundingBox;
        this.abelKit = abelKit;
        this.vehicleTypes = vehicleTypes;
        this.shift = shift;

        //Load modal into content_container

        shift.localShift.vehicle.vehicleTypeName = this.vehicleTypes.find(
            v => v.typeId == shift.localShift.vehicle.vehicleType
        ).name;
        $("#scheduler_modal_container").html(
            Templates["scheduler_modal"](shift)
        );

        if ($("#scheduler_modal_start").val() === ", ") {
            $("#scheduler_modal_start").val("");
        }

        if ($("#scheduler_modal_end").val() === ", ") {
            $("#scheduler_modal_end").val("");
        }

        $("#scheduler_modal").modal();
        $("#scheduler_modal").on("hide.bs.modal", function() {
            modalClosedCallback();
        });

        const placeSelectionCallback = (evt, suggestion) => {
            let id;
            if (!evt.target) {
                id = evt.split("#").length === 2 ? evt.split("#")[1] : evt;
            } else {
                id = evt.target.id;
            }

            let which = id == "scheduler_modal_start" ? "start" : "end";
            this.shift.localShift[which + "Location"] = suggestion.location;

            $("#scheduler_modal_" + which).val(suggestion.location.POIName);

            $("#scheduler_modal_" + which).data(
                "oldVal",
                $("#scheduler_modal_" + which).val()
            );
            $("#scheduler_modal_" + which)
                .parent()
                .parents(".form-group")
                .removeClass("has-error")
                .addClass("has-success");
        };

        this.autocomplete = new Autocomplete(
            abelKit,
            ["#scheduler_modal_start", "#scheduler_modal_end"],
            placeSelectionCallback,
            false,
            true
        );

        this.attachListeners();
    }

    attachListeners() {
        this.attachLocationListener();
        this.attachDriverListener();
        this.attachVehicleListener();
    }

    attachLocationListener() {
        this.attachInputListener($("#scheduler_modal_start"));

        this.attachInputListener($("#scheduler_modal_end"));

        $("#scheduler_modal_start, #scheduler_modal_end").focus(evt => {
            let which =
                evt.target.id == "scheduler_modal_start" ? "start" : "end";
            $("#scheduler_modal_" + which).select();
        });
    }

    attachDriverListener() {
        const _this = this;
        var timeOut = null;

        var scheduler_modal_driver_typeahead = function findMatches(
            query,
            syncResults,
            asyncResults
        ) {
            if (timeOut != null) clearTimeout(timeOut);
            timeOut = setTimeout(queryAutocomplete, 200);

            function queryAutocomplete() {
                _this.abelKit
                    .AbelAdminService()
                    .getDrivers(query)
                    .then(response => {
                        asyncResults(response.drivers);
                    })
                    .catch(error => {
                        $.bootstrapGrowl(error.message, {
                            delay: 2000,
                            type: "danger"
                        });
                    });
            }
        };

        const element = $("#scheduler_modal_driver");

        element.typeahead(null, {
            name: "autocomplete",
            displayKey: "name",
            source: scheduler_modal_driver_typeahead,
            limit: 10,
            templates: {
                suggestion: function(data) {
                    return `<div><strong>${data.name}</strong></div>`;
                }
            }
        });

        element.focus(() => {
            element.select();
        });

        this.attachInputListener(
            element,
            newVal => {
                if (newVal == "") delete this.shift.localShift.driver;
            },
            true
        );

        element.bind(
            "typeahead:selected typeahead:autocomplete",
            (evt, suggestion) => {
                this.shift.localShift.driver = suggestion;
                element.data("oldVal", element.val());
                element
                    .parents(".form-group")
                    .removeClass("has-error")
                    .addClass("has-success");
            }
        );
    }

    attachVehicleListener() {
        const _this = this;

        const licensePlateElement = $("#scheduler_modal_license_plate");
        const vehicleTypeElement = $("#scheduler_modal_vehicle_type_name");

        $('input[name="scheduler_modal_vehicle_radios"]').change(function() {
            if ($("#scheduler_modal_vehicle_radio_type").prop("checked")) {
                licensePlateElement.val("");
                vehicleTypeElement
                    .parents(".form-group")
                    .addClass("has-error")
                    .removeClass("has-success");
                licensePlateElement
                    .parents(".form-group")
                    .removeClass("has-error")
                    .removeClass("has-success");
            } else {
                vehicleTypeElement.val("");
                licensePlateElement
                    .parents(".form-group")
                    .addClass("has-error")
                    .removeClass("has-success");
                vehicleTypeElement
                    .parents(".form-group")
                    .removeClass("has-error")
                    .removeClass("has-success");
            }
        });

        //License plate async typeahead
        var timeOut = null;
        var scheduler_modal_license_plate_typeahead = function findMatches(
            query,
            syncResults,
            asyncResults
        ) {
            if (timeOut != null) clearTimeout(timeOut);
            timeOut = setTimeout(queryAutocomplete, 200);

            function queryAutocomplete() {
                _this.abelKit
                    .AbelAdminService()
                    .getVehicles(query)
                    .then(response => {
                        asyncResults(response.vehicles);
                    })
                    .catch(error => {
                        $.bootstrapGrowl(error.message, {
                            delay: 2000,
                            type: "danger"
                        });
                    });
            }
        };

        licensePlateElement.typeahead(null, {
            name: "autocomplete",
            displayKey: "licensePlate",
            source: scheduler_modal_license_plate_typeahead,
            limit: 10,
            templates: {
                suggestion: function(data) {
                    return `<div><strong>${data.licensePlate}</strong></div>`;
                }
            }
        });

        licensePlateElement.focus(() => {
            licensePlateElement.select();
        });

        this.attachInputListener(licensePlateElement, () => {}, false, [
            vehicleTypeElement
        ]);

        licensePlateElement.bind(
            "typeahead:selected typeahead:autocomplete",
            (evt, suggestion) => {
                licensePlateElement
                    .parents(".form-group")
                    .find(":radio")
                    .prop("checked", true);
                $('input[name="scheduler_modal_vehicle_radios"]').trigger(
                    "change"
                );
                this.shift.localShift.vehicle = suggestion;
                licensePlateElement.data("oldVal", licensePlateElement.val());
                licensePlateElement
                    .parents(".form-group")
                    .removeClass("has-error")
                    .addClass("has-success");
            }
        );

        //Vehicle type sync typeahead
        var scheduler_modal_vehicle_type_typeahead = function findMatches(
            query,
            syncResults
        ) {
            let eligibleVehicleTypes = _this.vehicleTypes.filter(v =>
                v.name.toLowerCase().includes(query.toLowerCase())
            );
            syncResults(eligibleVehicleTypes);
        };

        vehicleTypeElement.typeahead(null, {
            name: "autocomplete",
            displayKey: "name",
            source: scheduler_modal_vehicle_type_typeahead,
            limit: 10,
            templates: {
                suggestion: function(data) {
                    return `<div><strong>${data.name}</strong></div>`;
                }
            }
        });

        vehicleTypeElement.focus(() => {
            vehicleTypeElement.select();
        });

        this.attachInputListener(vehicleTypeElement, () => {}, false, [
            licensePlateElement
        ]);

        vehicleTypeElement.bind(
            "typeahead:selected typeahead:autocomplete",
            (evt, suggestion) => {
                vehicleTypeElement
                    .parents(".form-group")
                    .find(":radio")
                    .prop("checked", true);
                $('input[name="scheduler_modal_vehicle_radios"]').trigger(
                    "change"
                );
                this.shift.localShift.vehicle = new AbelKitWebAdmin.Objects.AKVehicle(
                    {
                        licensePlate: "",
                        vehicleType: suggestion.typeId
                    }
                );
                vehicleTypeElement.data("oldVal", vehicleTypeElement.val());
                vehicleTypeElement
                    .parents(".form-group")
                    .removeClass("has-error")
                    .addClass("has-success");
            }
        );
    }

    attachInputListener(
        element,
        clearUnderlyingObjectFunction = () => {},
        optionalElement = false,
        otherElementsInRadio = []
    ) {
        element.data("oldVal", element.val());
        element.bind("propertychange change click keyup input paste", () => {
            if (element.data("oldVal") != element.val()) {
                //console.log('Input listener for '+ element.attr('id')+ ' triggered: oldVal ' + element.data('oldVal') +', newVal: ' + element.val());
                element.data("oldVal", element.val());

                //Check radio, if somewhere in parent
                element
                    .parents(".form-group")
                    .find(":radio")
                    .prop("checked", true);

                //Clear underlying element(s)
                clearUnderlyingObjectFunction(element.val());

                //Set validation of element itself: assume it's not valid and that it will be validated by the typeahead:autocomplete
                if (element.val() != "" || !optionalElement) {
                    element
                        .parents(".form-group")
                        .addClass("has-error")
                        .removeClass("has-success");
                } else {
                    element
                        .parents(".form-group")
                        .removeClass("has-error")
                        .removeClass("has-success");
                }

                //Clear validation of other elements in radio
                for (const otherElement of otherElementsInRadio) {
                    otherElement
                        .parents(".form-group")
                        .removeClass("has-error")
                        .removeClass("has-success");
                    otherElement.val("");
                }
            }
        });
    }

    checkValues() {
        if ($("#scheduler_modal_vehicle_radio_type").prop("checked")) {
            if (
                $("#scheduler_modal_vehicle_type_form_group").hasClass(
                    "has-error"
                )
            )
                return false;
        } else {
            if (
                $("#scheduler_modal_license_plate_form_group").hasClass(
                    "has-error"
                )
            )
                return false;
        }

        if ($("#scheduler_modal_driver_form_group").hasClass("has-error"))
            return false;
        if (
            $("#scheduler_modal_start")
                .parent()
                .hasClass("has-error")
        )
            return false;
        if (
            $("#scheduler_modal_end")
                .parent()
                .hasClass("has-error")
        )
            return false;

        return true;
    }

    resetShift() {
        if (this.shift.serverShift != null) {
            this.shift.localShift.driver = new AbelKitWebAdmin.Objects.AKDriver(
                this.shift.serverShift.driver
            );
            this.shift.localShift.vehicle = new AbelKitWebAdmin.Objects.AKVehicle(
                this.shift.serverShift.vehicle
            );
            this.shift.localShift.startLocation = new AbelKitWebAdmin.Objects.AKLocationBase(
                this.shift.serverShift.startLocation
            );
            this.shift.localShift.endLocation = new AbelKitWebAdmin.Objects.AKLocationBase(
                this.shift.serverShift.endLocation
            );
        }
    }

    hide() {
        $("#scheduler_modal").modal("hide");
    }
}
