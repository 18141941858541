/*global updateTemplate, $*/

import AbelKit from 'abelkit-js';
import SchedulerController from './Components/SchedulerController';
import TripController from './Components/TripController';
import PromocodeController from './Components/PromocodeController';
import MeetingPointController from './Components/MeetingPointController';

/*import $ from 'jquery';
window.jQuery = window.$ = $;
require('bootstrap');*/

export default class AdminPanel {
    constructor() {
        let config;
        try {
            config = require('../../config');
        } catch (e) {
            const config = {};
        }
        this.abelKit = new AbelKit(config);

        this.abelKit.adminLogin(this.onLogin, (type, error) => {
            $.bootstrapGrowl(error, { delay: 2000, type: 'danger' });
        });

        this.view = null;

        $(window).bind('storage', () => {
            let contracts = this.abelKit._admin.get('contracts');
            if (contracts != null) {
                let localStorageContractId = parseInt(
                    localStorage.getItem(
                        this.abelKit._admin.get('contracts').activeStorageKey
                    )
                );
                if (
                    localStorageContractId !=
          this.abelKit._admin.get('contracts').getActive().id
                ) {
                    this.setContract(localStorageContractId);
                }
            }
        });

        if (config && config.appVersion) {
            $('#version_info').html('Version: ' + config.appVersion);
        }
    }

    onLogin() {
    //Set contract
        updateTemplate();
        $(window).hashchange();
    }

    scheduler() {
        this.view = new SchedulerController(this.abelKit);
    }

    trip() {
        this.view = new TripController(this.abelKit);
    }

    promocode() {
        this.view = new PromocodeController(this.abelKit);
    }

    meetingPoints() {
        this.view = new MeetingPointController(this.abelKit);
    }

    setContract(contractId) {
        this.abelKit.setContract(contractId);
        updateTemplate();
        $(window).hashchange();
    }

    logout() {
        this.abelKit.adminLogout();
        $('#content_container').html('Close your browser now.');
        $('#user_name').html('');
    }
}
