import Papa from 'papaparse';
import * as AbelKitWebAdmin from 'abelkit-js';
import Polygon from './Polygon';

const numberOfColumns = 2;

export default class PolygonUploader {
    constructor(map) {
        this.map = map;
    }

    parse(file) {
        return new Promise((resolve, reject) => {
            if(file == undefined || file.name.split('.').pop().toLowerCase() !=='csv') {
                throw `Please provide a valid CSV file to be uploaded.`;
            }
            Papa.parse(file, {
                complete: (results) => {
                    let data = [];
                    //Remove first line, as it contains headers
                    results.data.shift();
                    for (const [i, line] of results.data.entries()) {
                        try {
                            data.push(this.parseLine(line));
                        } catch (e) {
                            return reject(`File could not be processed as the format is wrong. Please correct the following error: "${e}" in the uploaded CSV on line: ${i+1}`);
                        }
                    }

                    for (const line of data) {
                        this.map.polygons.push(line.polygon);
                    }

                    this.map.redraw();

                    return resolve();
                },
                error: (err) => {
                    reject(err);
                },
                header: false,
                skipEmptyLines: true
            });
        });
    }

    parseLine(line) {
        if (line.length !== numberOfColumns)  {
            throw `Line has ${line.length} columns, must have ${numberOfColumns}`;
        }

        let polygon = null;
        const polygonReference = line[0];
        const polygonCoordinates = line[1];

        if(polygonReference === '') {
            throw `Please enter a unique name for the polygon`;
        }
        if(polygonCoordinates === '') {
            throw `Please enter coordinates for the polygon`;
        }

        if(!this.isUniquePolygonReference(polygonReference)) {
            throw `A polygon with the same name: ${polygonReference} already exists. Specify another name.`
        }

        try {
            polygon = new Polygon({
                path: JSON.parse(polygonCoordinates),
                reference: polygonReference,
            }, true);
        } catch (e) {
            throw `The polygon coordinates must be a valid JSON array of points, example format: [{"x":4.82316970825195,"y":52.2540782857686},{"x":4.81252670288085,"y":52.2413612622767}]`;
        }

        return {
            polygon: polygon,
        };
    }

     isUniquePolygonReference(polygonReference) {
        for (let polygon of this.map.polygons) {
            if (polygon instanceof Polygon && polygon.localObject.reference == polygonReference) {
                return false;
            }
        }
        return true;
    }
}