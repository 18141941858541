import * as AbelKitWebAdmin from 'abelkit-js';
import Polygon from './Polygon';
import Papa from 'papaparse';

export default class Uploader {
    constructor(file, map) {
        this.file = file;
        this.map = map;
    }

    /*
        Parse the CSV
    */
    parse() {
        return new Promise((resolve, reject) => {
            Papa.parse(this.file, {
                complete: (results) => {
                    let data = [];
                    //Remove first line, as it contains headers
                    results.data.shift();

                    for (const line of results.data) {
                        try {
                            data.push(this.parseLine(line));
                        } catch (e) {
                            reject(e);
                        }
                    }

                    let currentPolygon = null;
                    let currentMeetingpoint = null;

                    for (const line of data) {
                        if (line.polygon) {
                            if (!line.polygon.meetingpointsLoaded && line.polygon.localObject != null) {
                                // polygon has loaded meeting points
                                line.polygon.meetingpointsLoaded = true;
                            }

                            this.map.polygons.push(line.polygon);
                            currentPolygon = line.polygon;
                        } else if (!currentPolygon) {
                            reject('First data line should contain a polygon coordinates-list');
                        }

                        if (line.meetingpoint && currentPolygon) {
                            currentPolygon.localObject.meetingpoints.push(line.meetingpoint);
                            currentMeetingpoint = line.meetingpoint;
                        }

                        if (currentMeetingpoint) {
                            currentMeetingpoint.languages.push(line.meetingpointLanguage);
                        } else {
                            reject('First data line should contain meeting point coordinates');
                        }
                    }

                    this.map.redraw();

                    resolve();
                },
                error: (err) => {
                    reject(err);
                },
                header: false,
                skipEmptyLines: true
            });
        });
    }

    parseLine(line) {
        if (line.length < 5) throw 'Line only has ' + line.length + ' columns.';

        let polygon = null;
        let meetingpoint = null;
        let meetingpointLanguage = null;
        try {
            const polygonCoordinates = line[0];
            const meetingPointCoordinates = line[1];
            const language = line[2];
            const name = line[3];
            const description = line[4];

            if (polygonCoordinates != '') {
                polygon = new Polygon({
                    path: JSON.parse(polygonCoordinates)
                }, true);
            }

            // TODO: make this dynamic, instead of only url
            const url = line[5] || ' ';
            const metadata = new AbelKitWebAdmin.Objects.AKAMeetingPointMetadata({
                url: url
            });

            const notavailable = line[6] || '';

            if (meetingPointCoordinates != '') {
                meetingpoint = new AbelKitWebAdmin.Objects.AKAMeetingPoint({
                    position: JSON.parse(meetingPointCoordinates),
                    languages: [],
                    metadata: metadata,
                    notavailable: notavailable
                });
            }

            meetingpointLanguage = new AbelKitWebAdmin.Objects.AKAMeetingPointLanguage({
                language: language,
                name: name,
                description: description
            });
        } catch (e) {
            throw e.message.message;
        }

        return {
            polygon: polygon,
            meetingpoint: meetingpoint,
            meetingpointLanguage: meetingpointLanguage
        };
    }
}
