import * as AbelKitWebAdmin from 'abelkit-js';

export default class Polygon {
    constructor(polygon, localOnly = true) {
        if (!localOnly) {
            this.serverObject = new AbelKitWebAdmin.Objects.AKAMeetingPointArea(polygon);
        } else {
            this.serverObject = null;
        }
        this.localObject = new AbelKitWebAdmin.Objects.AKAMeetingPointArea(polygon);
        this.meetingpointsLoaded = false;
        this.layerId = null;
    }


    equal() {
        const bothNull = this.localObject == null && this.serverObject == null;
        const bothNotNull = this.localObject != null && this.serverObject != null;
        return (
            bothNull
            || (bothNotNull && this.localObject.equal(this.serverObject))
        );
    }

    reset() {
        if (this.serverObject != null) {
            this.localObject = new AbelKitWebAdmin.Objects.AKAMeetingPointArea(this.serverObject);
        }
    }

}
