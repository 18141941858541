import * as AbelKitWebAdmin from 'abelkit-js';
import Shift from './Shift';
import Papa from 'papaparse';

export default class Uploader {
    constructor(file, canvas) {
        this.file = file;
        this.canvas = canvas;
    }

    /*
        Parse the CSV
    */
    parse(vehiclesForContract) {
        return new Promise((resolve, reject) => {
            Papa.parse(this.file, {
                complete: (results) => {
                    let shifts = [];
                    //Remove first line, as it contains headers
                    results.data.shift();

                    for (const line of results.data) {
                        try {
                            shifts.push(this.parseLine(line,vehiclesForContract));
                        } catch (e) {
                            reject(e);
                        }
                    }

                    for (let shift of shifts) {
                        this.canvas.shifts.push(shift);
                    }
                    this.canvas.render();

                    resolve();
                },
                error: (err) => {
                    reject(err);
                },
                header: false,
                skipEmptyLines: true
            });
        });
    }

    parseLine(line, vehiclesForContract) {
        if (line.length < 12) throw('Line only has ' + line.length + ' columns.');

        this.canvas.localShifts++;
        let shift = {
            shiftId: this.canvas.localShifts,
            startTime: line[4],
            endTime: line[5],
            state: 'inactive',
            startLocation: {
                locationType:  'gpscoord',
                coordinate:  {x: this.parseCsvFloat(line[7]), y: this.parseCsvFloat(line[6])},
                geoType: 'wgs84',
                POIName: line[8]
            },
            endLocation: {
                locationType:  'gpscoord',
                coordinate:  {x: this.parseCsvFloat(line[10]), y: this.parseCsvFloat(line[9])},
                geoType: 'wgs84',
                POIName: line[11]
            },
        };

        if (line[0] != '') {
            shift.driver = {
                id: parseInt(line[0]),
                hashId: '',
                firstName: line[1],
                lastName: '',
                telephone: '',
                email: '',
                driverCardId: 0,
                companyId: 0
            };
        }

        shift.vehicle = {};
        if (line[2] != '') {
            shift.vehicle.licensePlate = line[2];

            var vehicleForLicensPlate = vehiclesForContract.filter(function(item){if(item.licensePlate == shift.vehicle.licensePlate) return lookup_item})[0];
            if(vehicleForLicensPlate && vehicleForLicensPlate !== 'undefined'){
                shift.vehicle = vehicleForLicensPlate;
            }
        }
        else if (line[3] != '') {
            shift.vehicle.vehicleType = line[3];
            var vehicleForType = vehiclesForContract.filter(function(item){if(item.vehicleType == shift.vehicle.vehicleType) return lookup_item})[0];

            if(vehicleForType && vehicleForType !== 'undefined'){
                shift.vehicle = vehicleForType;
            }
        }

        try {
            return new Shift(new AbelKitWebAdmin.Objects.AKShift(shift), 'local');
        } catch (e) {
            throw(e.message.message);
        }
    }

    parseCsvFloat(string) {
        string.replace(/,/g,'.');
        string.replace(/\.(?=.*\.)/g,'');
        return string;
    }
}
