/*global moment, $*/

/*
  TODO:
  - automatically fill vehicle and location based on driver chosen
  - auto generate bars
  - render stats (basic)
  - use 15 min grid for shift start/ end
  D3 API: https://github.com/d3/d3/blob/master/API.md

*/

import BaseController from './BaseController';
import Canvas from './Scheduler/Canvas';
import Shift from './Scheduler/Shift';
import Modal from './Scheduler/Modal';
import Uploader from './Scheduler/Uploader';


//import Statistics from './Scheduler/Statistics';


export default class SchedulerController extends BaseController {
    constructor(abelKit) {
        super(abelKit);

        let now = moment().tz(abelKit._admin.get('timezone'));
        this.attachListeners();

        //Initialize with today
        this.canvas = new Canvas(
            '#scheduler_container', 400,
            now.startOf('day').toDate(),
            now.endOf('day').toDate(),
            (shift) => {
                this.showModal(shift);
            },
            (shift, successCallback) => {
                this.removeShift(shift, successCallback);
            },
            this.abelKit._admin.get('contracts').getSettingForSelectedContract('vehicleTypes')[0]
        );

        this.changeDate([]);
    }


    /**
    * Import shifts from the server for a specific time frame without discarding local changes
    */
    importFromServer(startTime, endTime) {
        //Show loading image
        let alert = $.bootstrapGrowl('Loading...');
        let importStepsCompleted = 0;

        let importStepCompleted = () => {
            importStepsCompleted++;
            if (importStepsCompleted == 2) {
                alert.alert('close');
                this.canvas.render();
            }
        };

        let idsTouched = [];
        this.abelKit.AbelAdminService().getShifts(startTime, endTime).then((data) => {
            for (const serverShift of data.shifts) {
                idsTouched.push(serverShift.shiftId);
                let i = this.canvas.shifts.findIndex((l) => {
                    return (l.type != 'local' && l.serverShift.shiftId == serverShift.shiftId);
                });

                if (i != -1) {
                    //Present, update
                    this.canvas.shifts[i].serverShift = serverShift;
                } else {
                    //Not present, add
                    this.canvas.shifts.push(new Shift(serverShift));
                }
            }

            //Convert shifts not present but within the time interval to local shifts
            this.canvas.shifts = this.canvas.shifts.map((l) => {
                if (l.type == 'local') return l;

                const isInInterval = !(l.serverShift.startTime >= endTime || l.serverShift.endTime <= startTime);
                if (!idsTouched.includes(l.serverShift.shiftId) && isInInterval) {
                    //Convert to local shift
                    l.type = 'local';
                    l.serverShift = null;
                }
                return l;
            });
            importStepCompleted();
        }).catch((error) => {
            $.bootstrapGrowl(error.message, {'delay':2000, 'type':'danger'});
        });

        this.abelKit.AbelAdminService().getTripForecast(startTime, endTime).then((data) => {
            //Remove all statistics for this period
            this.canvas.statistics = this.canvas.statistics.filter((l) => {
                return (l.startTime >= endTime || l.endTime <= startTime);
            });
            this.canvas.statistics = this.canvas.statistics.concat(data.tripForecast);
            importStepCompleted();
        }).catch((error) => {
            $.bootstrapGrowl(error.message, {'delay':2000, 'type':'danger'});
        });
    }

    showModal(shift) {
        let mapBoundingBox = this.abelKit._admin.get('contracts').getSettingForSelectedContract('mapBoundingBox');
        let vehicleTypes = this.abelKit._admin.get('contracts').getSettingForSelectedContract('vehicleTypes');
        this.modal = new Modal(shift, mapBoundingBox, vehicleTypes, () => { this.canvas.render(); }, this.abelKit);
    }

    saveModal() {
        if (this.modal.checkValues()) {
            this.modal.hide();
            this.canvas.render();
        } else {
            $.bootstrapGrowl('Please solve all red input boxes.', {'delay':2000, 'type':'danger'});
        }
    }

    //Triggered in modal
    resetModalShift() {
        if (confirm('Are you sure?') == true) {
            this.modal.resetShift();
            this.modal.hide();
            this.canvas.render();
        } else {
            $.bootstrapGrowl('No data reset.', {'delay':2000, 'type':'info'});
        }
    }

    resetShifts() {
        if (confirm('Are you sure?') == true) {
            this.canvas.shifts = this.canvas.shifts.filter((v) => v.type != 'local');
            for (const shift of this.canvas.shifts) {
                shift.reset();
            }
            this.canvas.render();
        } else {
            $.bootstrapGrowl('No data reset.', {'delay':2000, 'type':'info'});
        }
    }

    //Triggered in modal
    removeModalShift() {
        this.removeShift(this.modal.shift, () => {
            this.canvas.shifts = this.canvas.shifts.filter((l) => {
                return !(l.localShift.shiftId == this.modal.shift.localShift.shiftId && l.type == this.modal.shift.type);
            });
            this.modal.hide();
        });
    }

    removeShift(shift, successCallback) {
        if (confirm('Are you sure?') == true) {
            if(shift.type == 'local') {
                successCallback();
            } else {
                this.abelKit.AbelAdminService().removeShift(shift.serverShift).then(() => {
                    successCallback();
                }).catch((error) => {
                    $.bootstrapGrowl(error.message, {'delay':2000, 'type':'danger'});
                });
            }
        } else {
            $.bootstrapGrowl('No changes made.', {'delay':2000, 'type':'info'});
        }
    }

    driverlogOnFromModalShift() {
        if (typeof this.modal.shift.localShift.driver == 'undefined') {
            $.bootstrapGrowl('A specific driver is required to start a shift', {'delay':2000, 'type':'danger'});
            return;
        }
        if (typeof this.modal.shift.localShift.vehicle.licensePlate == 'undefined' || this.modal.shift.localShift.vehicle.licensePlateVehicles == '') {
            $.bootstrapGrowl('A specific vehicle is required to start a shift', {'delay':2000, 'type':'danger'});
            return;
        }

        this.abelKit.AbelAdminService().driverLogOn(this.modal.shift.localShift.AKAAddShift).then((data) => {
            this.processUpdatedServerShift(data.shift, this.modal.shift);
            this.canvas.render();
            this.modal.hide();
        }).catch((error) => {
            $.bootstrapGrowl(error.message, {'delay':2000, 'type':'danger'});
        });
    }

    driverlogOffFromModalShift() {
        if (typeof this.modal.shift.localShift.driver == 'undefined') {
            $.bootstrapGrowl('No driver is specified', {'delay':2000, 'type':'danger'});
            return;
        }

        this.abelKit.AbelAdminService().driverLogOff(this.modal.shift.localShift.driver.id).then((data) => {
            this.processUpdatedServerShift(data.shift, this.modal.shift);
            this.canvas.render();
            this.modal.hide();
        }).catch((error) => {
            $.bootstrapGrowl(error.message, {'delay':2000, 'type':'danger'});
        });
    }

    syncShifts() {
        for (let i=this.canvas.shifts.length-1;i>=0;i--) {
            let shift = this.canvas.shifts[i];
            if (!shift.equal()) {
                if (shift.type != 'local') {
                    //Update = remove + add
                    let alert = $.bootstrapGrowl('Saving...');
                    this.abelKit.AbelAdminService().removeShift(shift.serverShift).then(() => {
                        this.abelKit.AbelAdminService().addShifts(shift.localShift.AKAAddShifts).then((data) => {
                            this.canvas.shifts[i] = new Shift(data.shiftResponses[0].shift);
                            this.canvas.render();

                            alert.alert('close');
                            this.syncShifts(); //TODO: ugly and remove once backen support adding multiple shifts
                        }).catch((error) => {
                            this.canvas.shifts[i].type = 'local';
                            alert.alert('close');
                            this.canvas.render();
                            $.bootstrapGrowl(error.message, {'delay':2000, 'type':'danger'});
                        });
                    }).catch((error) => {
                        alert.alert('close');
                        this.canvas.render();
                        $.bootstrapGrowl(error.message, {'delay':2000, 'type':'danger'});
                    });
                } else {
                    //Add only
                    let alert = $.bootstrapGrowl('Saving...');
                    this.abelKit.AbelAdminService().addShifts(shift.localShift.AKAAddShifts).then((data) => {
                        this.canvas.shifts[i] = new Shift(data.shiftResponses[0].shift);
                        this.canvas.render();
                        alert.alert('close');
                        this.syncShifts(); //TODO: ugly and remove once backen support adding multiple shifts
                    }).catch((error) => {
                        alert.alert('close');
                        this.canvas.render();
                        $.bootstrapGrowl(error.message, {'delay':2000, 'type':'danger'});
                    });
                }
                break; //TODO: ugly and remove once backend support adding multiple shifts
            }
        }
    }

    /**
    * Update the local shift cache, assuming that the server shift is newer than the local shift
    *
    * @param {AKShift} serverShift
    * @param {Shift} localSchedulerShift - The shift from which the returned serverShift was derived
    */
    processUpdatedServerShift(serverShift, localSchedulerShift) {
        //Check if present
        let i = this.canvas.shifts.findIndex((l) => {
            return (l.type != 'local' && l.serverShift.shiftId == serverShift.shiftId);
        });

        let schedulerShift = new Shift(serverShift);
        let sameShift = false;
        if (i != -1) {
            //Present, update
            this.canvas.shifts[i] = schedulerShift;
            if (localSchedulerShift.type != 'local' && localSchedulerShift.serverShift.shiftId == serverShift.shiftId) {
                sameShift = true;
            }
        } else {
            //Not present, add
            this.canvas.shifts.push(schedulerShift);
        }

        if (!sameShift && localSchedulerShift.type == 'local') {
            //Remove localSchedulerShift
            this.canvas.shifts = this.canvas.shifts.filter((l) => {
                return !(l.type == 'local' && l.localShift.shiftId == localSchedulerShift.localShift.shiftId);
            });
        } else if (!sameShift) {
            //Reset localSchedulerShift
            this.canvas.shifts = this.canvas.shifts.map((l) => {
                if (l.type != 'local' && l.localShift.shiftId == localSchedulerShift.localShift.shiftId) {
                    l.localShift = l.serverShift;
                }
                return l;
            });
        }

    }

    /**
     * Changes the scheduler's date and improves
     *
     * The date used to determine the day must be the client time, in order for the day calculations to work across timezones
     * However, the date used to retrieve data must be converted to the correct timezone
     *
     * @param dateStringInLocalTimezone
     * @param offsetInMinutes
     */
    changeDate(dateStringInLocalTimezone, offsetInMinutes = 0) {

        let dateWithOffset = moment(dateStringInLocalTimezone).add(offsetInMinutes, 'minutes');
        $('#scheduler_control_time').val(dateWithOffset.format('YYYY-MM-DD'));

        let now = moment(dateStringInLocalTimezone).tz(this.abelKit._admin.get('timezone')).add(offsetInMinutes, 'minutes');
        let startOfDay = now.startOf('day').toDate();
        let endOfDay = now.endOf('day').toDate();

        this.canvas.startTime = startOfDay;
        this.canvas.endTime = endOfDay;

        this.canvas.render();
        this.importFromServer(startOfDay, endOfDay);
    }

    resyncDay() {
        this.importFromServer(this.canvas.startTime, this.canvas.endTime);
    }

    setToToday() {
        this.changeDate([]);
    }

    setToPreviousDay() {
        this.changeDate($('#scheduler_control_time').val(), -60*24);
    }

    setToNextDay() {
        this.changeDate($('#scheduler_control_time').val(), 60*24);
    }

    attachListeners() {
        $('#scheduler_control_time').change(() => {
            this.changeDate($('#scheduler_control_time').val());
            return false;
        });

        $('#scheduler_upload_item_form').submit(()=>this.submitFile(this.abelKit));
    }

    submitFile(abelKit){
        const file = $('#scheduler_upload_file')[0].files[0];
        const uploader = new Uploader(
            file,
            this.canvas
        );
        abelKit.AbelAdminService().getVehicles().then(function(data){
            uploader.parse(data.vehicles).then(() => {
                $('#scheduler_upload_modal').modal('hide');
                $('#scheduler_upload_file').val('');
                $.bootstrapGrowl('Shifts added locally. After inspection, press save to send them to the server.', {
                    'delay': 2000,
                    'type': 'success'
                });
            }).catch((error) => {
                $.bootstrapGrowl(error, {'delay': 5000, 'type': 'danger'});
                $('#scheduler_upload_file').val('');
            });
            return false;
        });
    }
}
