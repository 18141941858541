export default class Downloader {
    constructor(map) {
        this.map = map;
        this.downloadAllMeetingpoints();
    }

    downloadAllMeetingpoints() {
        this.completePolygons = 0;

        for (const polygon of this.map.polygons) {
            if (polygon.serverObject !== null && !polygon.meetingpointsLoaded) {
                //TODO: replace with batch request when available
                this.map.meetingPointController.importMarkersFromServer(
                    polygon,
                    () => this.completedPolygon()
                );
            } else {
                this.completedPolygon();
            }
        }
    }

    completedPolygon() {
        this.completePolygons++;

        if (this.completePolygons === this.map.polygons.length) {
            let data = [
                [
                    "Polygon coordinates-list in JSON format (only fill for first line of polygon)",
                    "Meeting point coordinates in JSON format (only fill for first line of meeting point)",
                    "Language",
                    "Meetingpoint name",
                    "Meeting point description",
                    "Url",
                    "Not available dates"
                ]
            ];

            for (const polygon of this.map.polygons) {
                let firstMeetingpoint = true;

                if (
                    !polygon.hasOwnProperty("localObject") ||
                    polygon.localObject === null ||
                    !polygon.localObject.hasOwnProperty("meetingpoints")
                ) {
                    // guard for `render-only` polygons
                    continue;
                }

                const meetingpoints = polygon.localObject.meetingpoints;
                if (
                    meetingpoints.length < 1 &&
                    polygon.localObject.hasOwnProperty("path")
                ) {
                    // no meetingpoints: only process bounding polygon
                    data.push([
                        JSON.stringify(polygon.localObject.path),
                        "",
                        "",
                        "",
                        ""
                    ]);
                }

                for (const meetingpoint of meetingpoints) {
                    let firstMeetingpointLanguage = true;
                    for (const meetingpointLanguage of meetingpoint.languages) {
                        data.push([
                            firstMeetingpoint
                                ? JSON.stringify(polygon.localObject.path)
                                : "",
                            firstMeetingpointLanguage
                                ? JSON.stringify(meetingpoint.position)
                                : "",
                            meetingpointLanguage.language,
                            meetingpointLanguage.name,
                            meetingpointLanguage.description,
                            "",
                            meetingpoint.notavailable
                                ? meetingpoint.notavailable
                                : ""
                        ]);

                        firstMeetingpoint = false;
                        firstMeetingpointLanguage = false;
                    }
                }
            }

            const now = new Date();
            const exportFilename =
                "meetingpoints at " +
                window.location.hostname +
                " on " +
                now.toISOString() +
                ".csv";

            const csvString = data
                .map(row =>
                    row.map(i => '"' + i.replace(/"/g, '""') + '"').join(",")
                )
                .join("\n");

            var csvData = new Blob([csvString], {
                type: "text/csv;charset=utf-8;"
            });
            //IE11 & Edge
            if (navigator.msSaveBlob) {
                navigator.msSaveBlob(csvData, exportFilename);
            } else {
                //In FF link must be added to DOM to be clicked
                var link = document.createElement("a");
                link.href = window.URL.createObjectURL(csvData);
                link.setAttribute("download", exportFilename);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }
}
