/* global $ */
import Map from "./../Map/Map";
import Polygon from "./../Meetingpoints/Polygon";
import * as AbelKitWebAdmin from "abelkit-js";

export default class MeetingpointMap extends Map {
    constructor(abelKit, meetingPointController) {
        super(abelKit);

        this.meetingPointController = meetingPointController;
        this.drawingManager = null;

        this.polygons = [];
        this.markers = [];
        this.selectedPolygon = false;

        // polygon colors
        this.polygonColorSelected = "#d9edf7";
        this.polygonColorSynced = "#63c261";
        this.polygonColorUnsynced = "#f0ad4e";

        this.markerOptions = {
            icon: {
                path:
                    "M0-48c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z",
                fillColor: "#583470",
                strokeColor: "#fff",
                fillOpacity: 0.9,
                scale: 0.75,
                strokeWeight: 1
            },
            clickable: true,
            draggable: true,
            zIndex: 2
        };

        this.polygonOptions = {
            fillColor: this.polygonColorUnsynced,
            strokeColor: "#999",
            fillOpacity: 0.4,
            strokeOpacity: 0.5,
            strokeWeight: 4,
            clickable: true,
            draggable: false,
            editable: false,
            zIndex: 1
        };
    }

    initialize(elementId) {
        super.initialize(elementId);

        this.drawingManager = new google.maps.drawing.DrawingManager({
            //drawingMode: google.maps.drawing.OverlayType.MARKER,
            drawingMode: null,
            drawingControl: true,
            drawingControlOptions: {
                position: google.maps.ControlPosition.TOP_CENTER,
                drawingModes: ["marker", "polygon"]
            },
            markerOptions: this.markerOptions,
            polygonOptions: this.polygonOptions
        });

        this.drawingManager.setMap(this.map);

        this.attachToolbarListeners();
    }

    attachToolbarListeners() {
        // map click listener
        this.map.addListener("click", () => {
            // because of the stopPropagation calls, this listener only gets
            // events that do not come through a marker or polygon
            this.selectPolygon(false);
        });

        // polygon creation listener
        this.drawingManager.addListener("polygoncomplete", polygon => {
            // get coordinates for polygon
            const coordinates = [];
            polygon
                .getPath()
                .forEach(position =>
                    coordinates.push({ x: position.lng(), y: position.lat() })
                );

            // get unique (temporary) id
            const identifier = Date.now();

            const polygonItem = new Polygon(
                new AbelKitWebAdmin.Objects.AKAMeetingPointArea({
                    id: identifier,
                    path: coordinates
                })
            );

            // set area reference
            polygon.meetingpointAreaId = identifier;

            this.attachPolygonListener(polygon);

            // set polygons in state
            this.polygons.push(polygonItem);
            this.polygons.push(polygon);

            this.selectPolygon(polygon);
        });

        // marker creation listener
        this.drawingManager.addListener("markercomplete", marker => {
            if (!this.selectedPolygon) {
                $.bootstrapGrowl("Select a meeting point first", {
                    delay: 2000,
                    type: "danger"
                });

                // don't render marker
                marker.setMap(null);
                return;
            }

            // create new meeting point
            const position = marker.getPosition();
            const meetingpoint = new AbelKitWebAdmin.Objects.AKAMeetingPoint({
                position: { x: position.lng(), y: position.lat() },
                languages: [],
                metadata: new AbelKitWebAdmin.Objects.AKAMeetingPointMetadata({
                    url: ""
                }),
                notavailable: marker.notavailable
            });

            // add meeting point to area
            const meetingArea = this.getSelectedArea();
            if (meetingArea) {
                meetingArea.localObject.meetingpoints.push(meetingpoint);
            }

            this.attachMarkerListener(marker, meetingpoint);

            // set marker in state
            this.markers.push(marker);

            this.meetingPointController.showModal(meetingpoint);
        });
    }

    /**
     * Attach listeners to a polygon
     *
     * @param polygon {google.maps.Polygon}
     */
    attachPolygonListener(polygon) {
        polygon.addListener("click", event => {
            // stop event propagation
            event.stop();

            this.selectPolygon(polygon);
        });
    }

    /**
     * Attach listeners to a marker
     *
     * @param marker       {google.maps.Marker}
     * @param meetingpoint {AbelKitWebAdmin.Objects.AKAMeetingPoint}
     */
    attachMarkerListener(marker, meetingpoint = false) {
        marker.addListener("click", event => {
            // stop event propagation
            event.stop();

            if (meetingpoint !== false) {
                this.meetingPointController.showModal(meetingpoint, () =>
                    this.removeMeetingpointMarker(marker, meetingpoint)
                );
            }
        });
    }

    /**
     * Remove a meetingpoint and it's marker.
     *
     * @param marker       {google.maps.Marker}
     * @param meetingpoint {AbelKitWebAdmin.Objects.AKAMeetingPoint}
     */
    removeMeetingpointMarker(marker, meetingpoint) {
        const meetingArea = this.getSelectedArea();

        if (meetingArea) {
            // remove meeting point from selected area
            meetingArea.localObject.meetingpoints = meetingArea.localObject.meetingpoints.filter(
                areaMeetingpoint => {
                    return areaMeetingpoint.id !== meetingpoint.id;
                }
            );
        }

        // remove marker
        marker.setMap(null);
    }

    /**
     * Select a specific polygon
     *
     * @param polygon
     */
    selectPolygon(polygon = false) {
        if (polygon === false) {
            this.redrawMeetingpoints(false);
            return;
        }

        if (polygon === this.selectedPolygon) {
            // polygon already selected
            return;
        }

        // deselect all other polygons
        for (const polygonItem of this.polygons) {
            if (!(polygonItem instanceof Polygon)) {
                // set unselected fill color
                polygonItem.setOptions(
                    Object.assign(this.polygonOptions, {
                        fillColor: this.polygonColorSynced
                    })
                );
            }
        }

        // select this polygon
        this.selectedPolygon = polygon;
        polygon.setOptions(
            Object.assign(this.polygonOptions, {
                fillColor: this.polygonColorSelected
            })
        );
        if (polygon.meetingpointAreaId) {
            // get referenced area
            const meetingArea = this.getSelectedArea();
            if (meetingArea.localObject.reference) {
                $("#polygon_name").html(
                    `Selected polygon: <b>${meetingArea.localObject.reference}</b>`
                );
            } else {
                $("#polygon_name").html("");
            }

            if (meetingArea.localObject.meetingpoints.length > 0) {
                // meeting points already imported for
                // polygon: render meeting points
                this.redrawMeetingpoints(meetingArea);

                return;
            }

            // import markers
            this.meetingPointController.importMarkersFromServer(
                meetingArea,
                () => {
                    if (this.selectedPolygon === polygon) {
                        // polygon is still selected: render
                        // meeting points
                        this.redrawMeetingpoints(meetingArea);
                    }
                }
            );
        } else {
            this.clearMarkers();
        }
    }

    /**
     * Get the appropriate meeting area for the
     * currently selected polygon.
     *
     * @return Polygon
     */
    getSelectedArea() {
        const selectedPolygon = this.selectedPolygon;

        if (!selectedPolygon.meetingpointAreaId) {
            // no reference to area
            return null;
        }

        return this.polygons.find(
            polygonItem =>
                polygonItem instanceof Polygon &&
                (polygonItem.hasOwnProperty("localObject") &&
                    polygonItem.localObject) &&
                polygonItem.localObject.id ===
                    selectedPolygon.meetingpointAreaId
        );
    }

    /**
     * Redraw all polygons.
     */
    redraw() {
        this.selectedPolygon = false;
        var counter = 0;
        for (const polygonItem of this.polygons) {
            if (polygonItem instanceof Polygon && polygonItem.localObject) {
                // polygon loaded from the server

                // construct polygon object
                const polygon = new google.maps.Polygon(this.polygonOptions);
                polygon.setPath(polygonItem.localObject.latLngs);
                polygon.setMap(this.map);
                this.attachPolygonListener(polygon);

                // set polygon fill color
                const fillColor = polygonItem.equal()
                    ? this.polygonColorSynced
                    : this.polygonColorUnsynced;
                polygon.setOptions(
                    Object.assign(this.polygonOptions, { fillColor: fillColor })
                );

                let areaId = polygonItem.localObject.id;
                if (!areaId) {
                    // new upload: generate unique (temporary) id
                    areaId = Date.now();
                    areaId += counter++;
                    polygonItem.localObject.id = areaId;
                }

                // set reference to area
                polygon.meetingpointAreaId = areaId;

                // set polygon in state
                this.polygons.push(polygon);
            }
        }
    }

    /*
     * Removes all meeting points from view and displays
     * those of a specific polygonId
     */
    redrawMeetingpoints(polygon = false) {
        if (polygon === false) {
            return;
        }

        this.clearMarkers();

        for (const meetingpoint of polygon.localObject.meetingpoints) {
            // construct marker object
            const marker = new google.maps.Marker(this.markerOptions);
            const position = new google.maps.LatLng(
                meetingpoint.position.y,
                meetingpoint.position.x
            );
            marker.setPosition(position);
            marker.setMap(this.map);
            this.attachMarkerListener(marker, meetingpoint);

            // set marker in state
            this.markers.push(marker);
        }
    }

    /**
     * Remove all markers.
     */
    clearMarkers() {
        for (const markerItem of this.markers) {
            // remove from map
            markerItem.setMap(null);
        }

        this.markers = [];
    }

    /**
     * Remove all meeting areas.
     */
    clearAreas() {
        for (const polygon of this.polygons) {
            // have it delete on sync with remote
            polygon.localObject = null;

            if (polygon instanceof google.maps.Polygon) {
                // remove polygon from map
                polygon.setMap(null);
            }
        }
    }

    /**
     * `Reset` button handler
     */
    reset() {
        // this.polygons = this.polygons.filter((i) => i.serverObject != null);
        // for (const polygon of this.polygons) {
        //     polygon.reset();
        // }
        // this.redraw();
    }

    /**
     * `Save` button handler
     */
    sync() {
        for (const polygonItem of this.polygons) {
            if (polygonItem instanceof Polygon) {
                if (polygonItem.equal()) {
                    continue;
                }

                let alert = $.bootstrapGrowl("Saving...");

                if (polygonItem.serverObject && !polygonItem.localObject) {
                    // existing area, no local instance: remove area
                    this.removeRemoteMeetingArea(polygonItem);
                } else if (polygonItem.serverObject) {
                    // existing area, and local instance: update area
                    this.updateRemoteMeetingArea(polygonItem);
                } else {
                    // only local instance: create new area
                    this.createRemoteMeetingArea(polygonItem);
                }

                alert.alert("close");
            }
        }

        $.bootstrapGrowl("Meetingpoints saved!", {
            delay: 2000,
            type: "success"
        });
    }

    /**
     * Resource calls to the server.
     */

    /**
     * @param polygon {Polygon}
     */
    removeRemoteMeetingArea(polygon) {
        this.abelKit
            .AbelAdminService()
            .removeMeetingPoint(polygon.serverObject.id)
            .then(() => {
                const index = this.polygons.indexOf(polygon);

                // remove local polygon
                this.polygons.splice(index, 1);
            })
            .catch(error => {
                this.redraw();

                $.bootstrapGrowl(error.message, {
                    delay: 2000,
                    type: "danger"
                });
            });
    }

    /**
     * @param polygon {Polygon}
     */
    updateRemoteMeetingArea(polygon) {
        this.abelKit
            .AbelAdminService()
            .updateMeetingPoint(polygon.localObject)
            .then(() => {
                polygon.serverObject = new AbelKitWebAdmin.Objects.AKAMeetingPointArea(
                    polygon.localObject
                );
            })
            .catch(error => {
                this.redraw();

                $.bootstrapGrowl(error.message, {
                    delay: 2000,
                    type: "danger"
                });
            });
    }

    /**
     * @param polygon {Polygon}
     */
    createRemoteMeetingArea(polygon) {
        // get temporary id
        const meetingArea = this.polygons.find(
            polygonItem =>
                !(polygonItem instanceof Polygon) &&
                polygonItem.meetingpointAreaId === polygon.localObject.id
        );

        this.abelKit
            .AbelAdminService()
            .addMeetingPoint(polygon.localObject)
            .then(data => {
                const newId = data.return.Id;

                // update existing polygons
                meetingArea.meetingpointAreaId = newId;
                polygon.localObject.id = newId;
                polygon.serverObject = new AbelKitWebAdmin.Objects.AKAMeetingPointArea(
                    polygon.localObject
                );
            })
            .catch(error => {
                this.redraw();

                $.bootstrapGrowl(error.message, {
                    delay: 2000,
                    type: "danger"
                });
            });
    }
}
