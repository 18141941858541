/*global $*/


import BaseController from './BaseController';
import Autocomplete from './Autocomplete/Autocomplete';

export default class PromocodeController extends BaseController {
    constructor(abelKit) {
        super(abelKit);

        const placeSelectionCallback = (evt,suggestion) => {
            let which = 'to';
            if (evt.target.id == 'promocode_from') {
                which = 'from';
            }
            $('#promocode_'+which+'_street_number').val(suggestion.location.houseNumber);
            $('#promocode_'+which+'_route').val(suggestion.location.street);
            $('#promocode_'+which+'_locality').val(suggestion.location.place);
            $('#promocode_'+which+'_country').val(suggestion.location.countryCode);
            $('#promocode_'+which+'_postal_code').val(suggestion.location.zip);
            $('#promocode_'+which+'_poi_name').val(suggestion.location.POIName);
            $('#promocode_'+which+'_lat').val(suggestion.location.coordinate.y);
            $('#promocode_'+which+'_lng').val(suggestion.location.coordinate.x);
            $('#promocode_'+which+'_active').prop('checked', true);
        };

        this.autocomplete = new Autocomplete(abelKit, ['#promocode_from', '#promocode_to'], placeSelectionCallback, true, true);
    }

    new() {
        $('#promocode_from, #promocode_to').keydown((evt) => {
            var keycode = event.keyCode;
            var valid =
                (keycode > 47 && keycode < 58)   || // number keys
                (keycode > 64 && keycode < 91)   || // letter keys
                (keycode > 95 && keycode < 112)  || // numpad keys
                (keycode > 185 && keycode < 193) || // ;=,-./` (in order)
                (keycode > 218 && keycode < 223);   // [\]' (in order)

            if (valid) {
                let which = 'to';
                if (evt.target.id == 'promocode_from') {
                    which = 'from';
                }
                this.clearLocation(which);
            }
        });
    }

    clearLocation(which) {
        $('#promocode_'+which+'_street_number').val('');
        $('#promocode_'+which+'_route').val('');
        $('#promocode_'+which+'_locality').val('');
        $('#promocode_'+which+'_country').val('');
        $('#promocode_'+which+'_postal_code').val('');
        $('#promocode_'+which+'_poi_name').val('');
        $('#promocode_'+which+'_lat').val('');
        $('#promocode_'+which+'_lng').val('');
    }

}
