/*global L*/

export default class Map {

    constructor(abelKit) {
        this.abelKit = abelKit;
        this.map = null;
        this.boundingBox = this.abelKit._admin.get('contracts').getSettingForSelectedContract('mapBoundingBox');
    }

    initialize(elementId) {
        // initialize map
        this.map = new google.maps.Map(
            document.getElementById(elementId),
            {
                disableDefaultUI: true,
                zoomControl: true,
            }
        );

        this.center();
    }

    center() {
        if (this.map != null) {
            this.map.fitBounds(this.bounds);
        }
    }

    get bounds() {
        const bounds = new google.maps.LatLngBounds();

        if (typeof this.boundingBox === 'undefined') {
            bounds.extend(new google.maps.LatLng(20, 20));
            bounds.extend(new google.maps.LatLng(40, 40));

            return bounds;
        }

        let minLat = this.boundingBox[0].lat;
        let maxLat = this.boundingBox[0].lat;
        let minLng = this.boundingBox[0].lng;
        let maxLng = this.boundingBox[0].lng;

        for (let LatLng of this.boundingBox) {
            if (LatLng.lat < minLat) {minLat = LatLng.lat;}
            if (LatLng.lat > maxLat) {maxLat = LatLng.lat;}
            if (LatLng.lng < minLng) {minLng = LatLng.lng;}
            if (LatLng.lng > maxLng) {maxLng = LatLng.lng;}
        }

        const lowerBound = new google.maps.LatLng(minLat, minLng);
        const upperBound = new google.maps.LatLng(maxLat, maxLng);

        bounds.extend(lowerBound);
        bounds.extend(upperBound);

        return bounds;
    }
}
