/*global $*/

import BaseController from './BaseController';
import Autocomplete from './Autocomplete/Autocomplete';

export default class TripController extends BaseController {

    constructor(abelKit) {
        super(abelKit);

        const completeFromLocationService = abelKit._admin.get('contracts').getSettingForSelectedContract('requireMeetingpoint') == false;
        const placeSelectionCallback = (elementId, suggestion) => {
            let which = 'to';
            if (elementId.includes("from")) {
                which = 'from';
            }

            // set value
            $('#trip_'+which).val(suggestion.location.POIName);

            $('#trip_'+which+'_street_number').val(suggestion.location.houseNumber);
            $('#trip_'+which+'_route').val(suggestion.location.street);
            $('#trip_'+which+'_locality').val(suggestion.location.place);
            $('#trip_'+which+'_country').val(suggestion.location.countryCode);
            $('#trip_'+which+'_postal_code').val(suggestion.location.zip);
            $('#trip_'+which+'_poi_name').val(suggestion.location.POIName);
            $('#trip_'+which+'_lat').val(suggestion.location.coordinate.y);
            $('#trip_'+which+'_lng').val(suggestion.location.coordinate.x);
        };

        this.autocomplete = new Autocomplete(abelKit, ['#trip_from', '#trip_to'], placeSelectionCallback, true, completeFromLocationService);
    }

    new() {
        $('#trip_from, #trip_to').keydown((evt) => {
            var keycode = event.keyCode;
            var valid =
                (keycode > 47 && keycode < 58)   || // number keys
                (keycode > 64 && keycode < 91)   || // letter keys
                (keycode > 95 && keycode < 112)  || // numpad keys
                (keycode > 185 && keycode < 193) || // ;=,-./` (in order)
                (keycode > 218 && keycode < 223);   // [\]' (in order)

            if (valid) {
                let which = 'to';
                if (evt.target.id == 'trip_from') {
                    which = 'from';
                }
                this.clearLocation(which);
            }
        });
    }

    clearLocation(which) {
        $('#trip_'+which+'_street_number').val('');
        $('#trip_'+which+'_route').val('');
        $('#trip_'+which+'_locality').val('');
        $('#trip_'+which+'_country').val('');
        $('#trip_'+which+'_postal_code').val('');
        $('#trip_'+which+'_poi_name').val('');
        $('#trip_'+which+'_lat').val('');
        $('#trip_'+which+'_lng').val('');
    }

}
