/*global $*/

import BaseController from './BaseController';
import MeetingpointMap from './Meetingpoints/MeetingpointMap';
import Polygon from './Meetingpoints/Polygon';
import * as AbelKitWebAdmin from 'abelkit-js';
import Modal from './Meetingpoints/Modal';
import Uploader from './Meetingpoints/Uploader';
import Downloader from './Meetingpoints/Downloader';
import PolygonUploader from "./Meetingpoints/PolygonUploader";
import MeetingpointUploader from "./Meetingpoints/MeetingpointUploader";

export default class MeetingPointController extends BaseController {
    constructor(abelKit) {
        super(abelKit);

        this.attachListeners();

        //Initialize map
        this.map = new MeetingpointMap(abelKit, this);
        this.map.initialize('meetingpoints_map');
        this.importPolygonsFromServer();

        this.modalDeleteHandler = () => {};
    }


    attachListeners() {

        $('body').on('click', '#meetingpoint_modal_upload', function () {
            $('.tab-pane.active').find('form').submit()
        });

        $('#meetingpoints_upload_item_form').submit(() => {
            //Remove existing polygons if requested
            if ($('#meetingpoints_remove_existing').prop('checked')) {
                this.map.clearAreas();
            }
            const file = $('#meetingpoints_upload_file')[0].files[0];
            const uploader = new Uploader(
                file,
                this.map
            );
            uploader.parse().then(() => {
                $('#meetingpoints_upload_modal').modal('hide');
                $('#meetingpoints_upload_file').val('');
                $.bootstrapGrowl('MeetingPoints added locally. After inspection, press save to send them to the server.', {'delay':2000, 'type':'success'});
            }).catch((error) => {
                $.bootstrapGrowl(error, {'delay':5000, 'type':'danger'});
                $('#meetingpoints_upload_file').val('');
            });
            return false;
        });

        $('#meetingpoints_upload_split_polygons_form').submit(() => {
            const file = $('#polygons_upload_file')[0].files[0];
            const uploader = new PolygonUploader(this.map);

            uploader.parse(file).then(() => {
                $('#meetingpoints_upload_modal').modal('hide');
                $('#polygons_upload_file').val('');
                $.bootstrapGrowl('Polygons added locally. After inspection, press save to send them to the server.', {'delay':2000, 'type':'success'});
            }).catch(((error) => {
                $.bootstrapGrowl(error, {'delay':5000, 'type':'danger'});
                $('#polygons_upload_file').val('');
            }))
        })

        $('#meetingpoints_upload_split_meetingpoints_form').submit(() => {
            const file = $('#meetingpoints_only_upload_file')[0].files[0];
            const uploader = new MeetingpointUploader(this.map);
            uploader.parse(file).then(() => {
                $('#meetingpoints_upload_modal').modal('hide');
                $('#meetingpoints_only_upload_file').val('');
                $.bootstrapGrowl('MeetingPoints added locally. After inspection, press save to send them to the server.', {'delay':2000, 'type':'success'});
            }).catch(((error) => {
                $.bootstrapGrowl(error, {'delay':5000, 'type':'danger'});
                $('#meetingpoints_only_upload_file').val('');
            }))
        })
    }


    /**
    * Import meeting points from the server
    */
    importPolygonsFromServer() {
        //Show loading image
        let alert = $.bootstrapGrowl('Loading...');

        let idsTouched = [];
        this.abelKit.AbelAdminService().getMeetingPoints().then((data) => {
            for (const serverObject of data.polygons) {
                idsTouched.push(serverObject.id);
                let i = this.map.polygons.findIndex((l) => {
                    return (l.serverObject != null && l.serverObject.id == serverObject.id);
                });

                if (i != -1) {
                    //Present, update
                    this.map.polygons[i].serverObject = new AbelKitWebAdmin.Objects.AKAMeetingPointArea(serverObject);
                } else {
                    //Not present, add
                    this.map.polygons.push(new Polygon(serverObject, false));
                }
            }

            //Convert shifts not present but within the time interval to local shifts
            this.map.polygons = this.map.polygons.map((l) => {
                if (l.serverObject == null) return l;

                if (!idsTouched.includes(l.serverObject.id)) {
                    //Convert to local shift
                    l.serverObject = null;
                }
                return l;
            });
            alert.alert('close');
            this.map.redraw();
        }).catch((error) => {
            $.bootstrapGrowl(error.message, {'delay':2000, 'type':'danger'});
            throw error;
        });
    }

    importMarkersFromServer(polygon, callback) {
        if (polygon.serverObject == null) {
            callback();
            return;
        }

        let alert = $.bootstrapGrowl('Loading...');

        this.abelKit.AbelAdminService().getMeetingPoint(polygon.serverObject.id).then((data) => {
            polygon.serverObject.meetingpoints = data.meetingpoints;

            if (!polygon.meetingpointsLoaded && polygon.localObject != null) {
                const meetingPointsCopy = new AbelKitWebAdmin.Objects.AKAMeetingPoints(data); //Create a new copy
                polygon.localObject.meetingpoints = meetingPointsCopy.meetingpoints;
                polygon.meetingpointsLoaded = true;
            }

            callback();
            alert.alert('close');
        }).catch((error) => {
            $.bootstrapGrowl(error.message, {'delay':2000, 'type':'danger'});
            throw error;
        });
    }

    reset() {
        if (confirm('Are you sure?') == true) {
            this.map.reset();
        } else {
            $.bootstrapGrowl('No data reset.', {'delay':2000, 'type':'info'});
        }
    }

    sync() {
        this.map.sync();
    }

    onDelete() {
        // call handler
        this.modalDeleteHandler();

        // hide the modal
        this.modal.hide('meetingpoints_modal');
    }

    showModal(meetingpoint, deleteHandler) {
        const settingsLanguages = this.abelKit._admin.get('contracts').getSettingForSelectedContract('languages');

        // set handler if available
        if (deleteHandler) {
            this.modalDeleteHandler = deleteHandler;
        }

        this.modal = new Modal(meetingpoint, settingsLanguages, this.abelKit, () => {}, !!deleteHandler);
    }

    download() {
        new Downloader(this.map);
    }
}
