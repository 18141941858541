import * as AbelKitWebAdmin from 'abelkit-js';

export default class Shift {
    constructor(shift, type = 'committed') {
        if (type != 'local') {
            this.serverShift = new AbelKitWebAdmin.Objects.AKShift(shift);
        } else {
            this.serverShift = null;
        }
        this.localShift = new AbelKitWebAdmin.Objects.AKShift(shift);

        /*var allowedTypes = [
            'actual',
            'committed', //shift is taken into account when deciding whether to accept incoming trips
            'uncommitted', //shift is not taken into account when deciding whether to accept incoming trips, but shift is open for drivers to sign up for
            'local' //shift has not been synced with server yet
        ];*/

        this.type = type;
        this.level = 1;
    }

    status() {
        if (this.equal()) {
            return this.serverShift.state.value;
        }
        return 'unsynced';
    }


    equal() {
        return (
            this.type != 'local'
            && this.localShift.equal(this.serverShift)
        );
    }

    reset() {
        if (this.type != 'local') {
            this.localShift = new AbelKitWebAdmin.Objects.AKShift(this.serverShift);
        }
    }

}
