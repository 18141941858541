import d3Tip from 'd3-tip';

export default class StatisticsRenderer {
    constructor(canvas) {
        this.canvas = canvas;
        this.transitionDuration = 500;
        this.horizontalPadding = 5;

        this.actualTip = d3Tip().attr('class', 'd3-tip').offset([-10, 0]).html(function(d) {
            return '<p>Booked: ' + d.bookedSeatMinutes.toFixed(0) + ' min.<br>Available: ' + d.availableSeatMinutes.toFixed(0) + ' min.<br>Current utilization: ' + d.getCurrentSeatUtilizationString() + '</p>';
        });
        this.expectedTip = d3Tip().attr('class', 'd3-tip').offset([-10, 0]).html(function(d) {
            return '<p>Expected booking: ' + d.expectationOfBookedSeatMinutesAtTimeOfPickup.toFixed(0) + ' min.<br>Available: ' + d.availableSeatMinutes.toFixed(0) + ' min.</p>';
        });

        /* Invoke the tip in the context of your visualization */
        this.canvas.statisticsChart.call(this.actualTip);
        this.canvas.statisticsChart.call(this.expectedTip);
    }

    updateShiftStatistics() {
        for (var i=0; i < this.canvas.statistics.length; i++) {
            const statistic = this.canvas.statistics[i];
            this.canvas.statistics[i].availableSeatMinutes = this.canvas.shifts.reduce((acc, d) => {
                let startTimeWithinStatistic = Math.max(
                    Math.min(d.localShift.startTime, statistic.endTime),
                    statistic.startTime
                );

                let endTimeWithinStatistic = Math.max(
                    Math.min(d.localShift.endTime, statistic.endTime),
                    statistic.startTime
                );

                const minutesInStatistic = (endTimeWithinStatistic - startTimeWithinStatistic)/ 60000; //Originally in milliseconds
                return acc + minutesInStatistic;
            }, 0);
        }
    }

    render(animate = false) {
        this.updateShiftStatistics();

        //Plot statistics bars
        var bars = this.canvas.statisticsChart.selectAll('g.barGroup').data(
            this.canvas.statistics,
            function(d) { return d.startTime; } //Determines id of each element
        ); //See https://bost.ocks.org/mike/join/

        //New statistics: blocks
        var newBars = bars.enter().append('g').attr('class','barGroup');
        this.addContentsToBarGroups(newBars);

        //Existing statistics: animate to new position
        this.layOutBarGroups(bars, animate);

        //Removed statistics
        this.removeBarGroups(bars.exit(), animate);
    }

    addContentsToBarGroups(barGroups) {
        barGroups.append('rect')
        .attr('class', 'statistics-bar actual')
        .on('mouseover', this.actualTip.show)
        .on('mouseout', this.actualTip.hide);

        barGroups.append('rect')
        .attr('class', 'statistics-bar expectation')
        .on('mouseover', this.expectedTip.show)
        .on('mouseout', this.expectedTip.hide);

        barGroups.append('text')
        .attr('class', 'statistics-text')
        .attr('text-anchor', 'middle');

        this.layOutBarGroups(barGroups, false);
    }

    layOutBarGroups(barGroups, animate = false) {
        var x = (d) => { //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Functions/Arrow_functions
            return this.canvas.xScale(d.startTime) + this.horizontalPadding;
        };

        var width = (d) => {
            var hstart = d.startTime;
            var hstop = d.endTime;
            return this.canvas.xScale(hstop)-this.canvas.xScale(hstart);
        };

        var actualBars = barGroups.selectAll('rect.statistics-bar.actual');
        var expectationBars = barGroups.selectAll('rect.statistics-bar.expectation');
        var text = barGroups.selectAll('text.statistics-text');

        if (animate) {
            actualBars = actualBars.transition().duration(this.transitionDuration);
            expectationBars = expectationBars.transition().duration(this.transitionDuration);
            text = text.transition().duration(this.transitionDuration);
        }

        actualBars
            .attr('class',(d) => {
                let classes = 'statistics-bar actual';
                if (d.performance  == 1) {
                    return  classes + ' too-high';
                } else if (d.performance  == -1) {
                    return classes + ' too-low';
                } else {
                    return classes + ' on-target';
                }
            })
            .attr('y', (d) => {
                //return top
                return this.canvas.yScaleForStatistics(d.currentSeatUtilization);
            })
            .attr('x',x)
            .attr('width', (d) => {
                return Math.max(width(d) - 2 * this.horizontalPadding,0);
            })
            .attr('height',(d) => {
                return this.canvas.yScaleForStatistics(0) - this.canvas.yScaleForStatistics(d.currentSeatUtilization);
            });

        expectationBars
            .attr('class',(d) => {
                let classes = 'statistics-bar expectation';
                if (d.performance  == 1) {
                    return  classes + ' too-high';
                } else if (d.performance  == -1) {
                    return classes + ' too-low';
                } else {
                    return classes + ' on-target';
                }
            })
            .attr('y', (d) => {
                //return top
                return this.canvas.yScaleForStatistics(d.expectedSeatUtilization);
            })
            .attr('x',x)
            .attr('width', (d) => {
                return Math.max(width(d) - 2 * this.horizontalPadding,0);
            })
            .attr('height',(d) => {
                return - this.canvas.yScaleForStatistics(d.expectedSeatUtilization) + this.canvas.yScaleForStatistics(d.currentSeatUtilization);
            });

        text
            .attr('y', (d) => {
                //return top
                return this.canvas.yScaleForStatistics(d.expectedSeatUtilization) - 1;
            })
            .attr('x',(d) => {
                //As we've set text-anchor to middle, x should be the middle of the bar
                return (this.canvas.xScale(d.startTime) + this.canvas.xScale(d.endTime))/2;
            })
            .text((d) => {
                return d.getExpectedSeatUtilizationPercentage(1);
            });
    }

    removeBarGroups(barGroups, animate) {
        var actualBars = barGroups.selectAll('rect.statistics-bar.actual');
        var expectationBars = barGroups.selectAll('rect.statistics-bar.expectation');

        var _barGroups = barGroups;

        if (animate) {
            //Otherwise animation wouldn't be nice
            expectationBars
                .attr('height',(d) => {
                    return this.canvas.yScaleForStatistics(d.expectedSeatUtilization);
                });


            actualBars = actualBars.transition().duration(this.transitionDuration);
            expectationBars = expectationBars.transition().duration(this.transitionDuration);
            _barGroups = _barGroups.transition().duration(this.transitionDuration);

            //Don't animate removal of barText and barHandlers, remove them immediately
        }

        actualBars.attr('height', 0);
        expectationBars.attr('height', 0);

        _barGroups.remove();
    }
}
