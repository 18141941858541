import * as d3 from "d3";
import Shift from "./Shift";
import ShiftRenderer from "./ShiftRenderer";
import StatisticsRenderer from "./StatisticsRenderer";
import * as AbelKitWebAdmin from "abelkit-js";

export default class Canvas {
    /*

    */
    constructor(
        selector,
        heightExMargin,
        startTime,
        endTime,
        showModalCallback,
        removeShiftCallback,
        defaultVehicleType
    ) {
        this.margin = { top: 20, right: 20, bottom: 20, left: 40, between: 20 };
        this.startTime = startTime;
        this.endTime = endTime;
        this.showModalCallback = showModalCallback;
        this.removeShiftCallback = removeShiftCallback;
        this.localShifts = 0;
        this.defaultVehicleType = defaultVehicleType;
        this.heighExMarginShareForStatistics = 0.2;

        this.svg = d3
            .select(selector)
            .append("div")
            .attr("class", "d3-container")
            .selectAll("svg")
            .data(d3.range(1))
            .enter()
            .append("svg")
            .attr("id", "viz")
            .attr(
                "width",
                d3
                    .select(selector)
                    .node()
                    .getBoundingClientRect().width
            )
            .attr(
                "height",
                heightExMargin + this.margin.top + this.margin.bottom
            );

        //statisticsGroup: a transform that shifts the axes and shift chart area corresponding to the margin
        this.statisticsGroup = this.svg
            .append("g")
            .attr(
                "transform",
                "translate(" + this.margin.left + "," + this.margin.top + ")"
            );

        //shiftGroup: a transform that shifts the axes and shift chart area corresponding to the margin
        let verticalShift =
            this.margin.top + this.heightForStatistics + this.margin.between;
        this.shiftGroup = this.svg
            .append("g")
            .attr(
                "transform",
                "translate(" + this.margin.left + "," + verticalShift + ")"
            );

        //statisticsChart: area for plotting the statistics bars
        this.statisticsChart = this.statisticsGroup
            .append("g")
            .attr("class", "chart");

        //shiftChart: area for plotting the shifts
        this.shiftChart = this.shiftGroup.append("g").attr("class", "chart");

        var _this = this;

        //Background to chart area to catch clicks
        this.shiftChart
            .append("rect")
            .attr("x", 0)
            .attr("y", 0)
            .attr("width", this.widthExMargin)
            .attr("height", this.heightForShifts)
            .attr("class", "aks-invisible")
            .on("click", function() {
                if (d3.event.altKey) {
                    var pos = d3.mouse(this);
                    var startTime = _this.xScale.invert(
                        _this.xScaleSnapped(pos[0])
                    );
                    _this.localShifts++;
                    var newShift = new Shift(
                        new AbelKitWebAdmin.Objects.AKShift({
                            shiftId: _this.localShifts,
                            vehicle: {
                                licensePlate: "",
                                vehicleType: _this.defaultVehicleType.typeId
                            },
                            startLocation: {
                                locationType: "gpscoord",
                                coordinate: { x: "", y: "" }, //TODO: get default for contract
                                geoType: "wgs84"
                            },
                            endLocation: {
                                locationType: "gpscoord",
                                coordinate: { x: "", y: "" }, //TODO: get default for contract
                                geoType: "wgs84"
                            },
                            startTime: startTime.toISOString(),
                            endTime: d3.timeHour
                                .offset(startTime, 5)
                                .toISOString(),
                            state: "inactive"
                        }),
                        "local"
                    );

                    _this.shifts.push(newShift);
                    _this.render();
                }
            });

        //A clippath to hide the overflow of shift bars left and right
        this.shiftChart
            .append("defs")
            .append("clipPath")
            .attr("id", "clip")
            .append("rect")
            .attr("width", this.widthExMargin)
            .attr("height", this.heightForShifts);

        //Axes
        this.xAxis = this.shiftGroup
            .append("g")
            .attr("class", "axis")
            .attr(
                "transform",
                "translate(" + 0 + ", " + this.heightForShifts + ")"
            );

        //Y-axis only for shifts
        /*this.yAxis = this.shiftGroup.append('g')
        .attr('class', 'axis');*/

        this.statisticsXAxis = this.statisticsGroup
            .append("g")
            .attr("class", "axis")
            .attr(
                "transform",
                "translate(" + 0 + ", " + this.heightForStatistics + ")"
            );

        this.statisticsRenderer = new StatisticsRenderer(this);

        this.shiftRenderer = new ShiftRenderer(this, () => {
            this.statisticsRenderer.render(true);
        });

        this.shifts = [];
        this.statistics = [];
    }

    get widthExMargin() {
        return (
            parseFloat(d3.select(this.svg.node()).attr("width")) -
            this.margin.left -
            this.margin.right
        );
    }

    set widthExMargin(widthExMargin) {
        d3.select(this.svg.node()).attr(
            "width",
            widthExMargin + this.margin.left + this.margin.right
        );
    }

    get heightExMargin() {
        return (
            parseFloat(d3.select(this.svg.node()).attr("height")) -
            this.margin.top -
            this.margin.bottom
        );
    }

    set heightExMargin(heightExMargin) {
        d3.select(this.svg.node()).attr(
            "height",
            heightExMargin + this.margin.top + this.margin.bottom
        );
    }

    get heightForStatistics() {
        return Math.floor(
            this.heighExMarginShareForStatistics * this.heightExMargin
        );
    }

    get heightForShifts() {
        return (
            this.heightExMargin - this.heightForStatistics - this.margin.between
        );
    }

    get xScale() {
        return d3
            .scaleTime()
            .domain([this.startTime, this.endTime])
            .range([0, this.widthExMargin]);
    }

    /*
        Provide the x-position and return the closest snapped x-position
    */
    xScaleSnapped(x) {
        let timestamp = this.xScale.invert(x);
        let coeff = 1000 * 60 * 15; //15 minute
        let rounded = new Date(Math.round(timestamp.getTime() / coeff) * coeff);
        return this.xScale(rounded);
    }

    get yScaleForShifts() {
        var maxDomain = 0;
        if (this.shifts.length > 0) {
            maxDomain = d3.max(this.shifts, function(d) {
                return d.level;
            });
        }

        return d3
            .scaleLinear()
            .domain([0, Math.max(maxDomain + 1, 10)]) //Always leave room to draw one more shift and show at least 10
            .range([this.heightForShifts, 0]);
    }

    get yScaleForStatistics() {
        var maxDomain = 0;
        if (this.statistics.length > 0) {
            maxDomain = d3.max(this.statistics, function(d) {
                return Math.max(
                    d.expectedSeatUtilization,
                    d.targetSeatUtilizationUpperBound
                );
            });
        }
        return d3
            .scaleLinear()
            .domain([0, maxDomain])
            .range([this.heightForStatistics, 0]);
    }

    updateAxes() {
        var xAxis = d3
            .axisBottom()
            .scale(this.xScale)
            .tickFormat(d3.timeFormat("%H:%M"));
        this.xAxis.call(xAxis);

        var statisticsXAxis = d3
            .axisBottom()
            .scale(this.xScale)
            .tickFormat(d3.timeFormat("%H:%M"));
        this.statisticsXAxis.call(statisticsXAxis);

        /*var yAxis = d3.axisLeft()
        .scale(this.yScaleForShifts);
        this.yAxis.call(yAxis);*/
    }

    render(animate = true) {
        this.statisticsRenderer.render(animate);
        this.shiftRenderer.render(animate);
        this.updateAxes();
    }
}
