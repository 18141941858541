/*global $, Templates */
import * as AbelKitWebAdmin from 'abelkit-js';

export default class Modal {
    constructor(meetingpoint, settingsLanguages, abelKit, modalClosedCallback, deleteHandler = false) {
    //this.shift = shift;
        this.abelKit = abelKit;
        this.meetingpoint = meetingpoint;

        //Check that all languages are set for this meeting point. If not, create them
        for (const settingsLanguage of settingsLanguages) {
            if (
                typeof meetingpoint.getLanguage(settingsLanguage.type) == 'undefined'
            ) {
                //Create
                meetingpoint.languages.push(
                    new AbelKitWebAdmin.Objects.AKAMeetingPointLanguage({
                        language: settingsLanguage.type,
                        name: '',
                        description: ''
                    })
                );
            }
            //Add languageName property
            const language = meetingpoint.getLanguage(settingsLanguage.type);
            language.languageName = settingsLanguage.name;
        }

        //Load modal into content_container
        let metadata_keys = this.abelKit._admin
            .get('contracts')
            .getSettingForSelectedContract('meetingPointMeta');

        // are needed in template
        meetingpoint.metadata_keys = metadata_keys;
        meetingpoint.isDeleteable = deleteHandler;

        $('#meetingpoints_modal_container').html(
            Templates['settings_meetingpoints_modal'](meetingpoint)
        );
        $('#meetingpoints_modal').modal();
        $('#meetingpoints_modal').on('hide.bs.modal', function() {
            modalClosedCallback();
        });

        for (const settingsLanguage of settingsLanguages) {
            this.attachInputListenerForLanguage(settingsLanguage.type);
        }

        for (const metadata_key of metadata_keys) {
            this.attachInputListenerForMetadata(metadata_key);
        }
    }

    attachInputListenerForLanguage(languageType) {
        this.attachInputListener(languageType, 'name');
        this.attachInputListener(languageType, 'description');
    }

    attachInputListenerForMetadata(metadata_key) {
        this.attachInputListenerMeta(metadata_key);
    }

    attachInputListener(languageType, property) {
        const element = $('#meetingpoints_' + languageType + '_' + property);
        //element.data('language', language);
        //element.data('property', property);
        element.bind('propertychange change click keyup input paste', () => {
            const language = this.meetingpoint.getLanguage(languageType);
            language[property] = element.val();
        });
    }

    attachInputListenerMeta(metadata_key) {
        const element = $('#meetingpoints_meta_' + metadata_key + '_value');
        //meetingpoints_meta_url_value
        element.bind('propertychange change click keyup input paste', () => {
            var metadata = this.meetingpoint.metadata[metadata_key];
            this.meetingpoint.metadata[metadata_key] = element.val();
        });
    }

    hide(id) {
        const elementId = id ? `#${id}` : '#scheduler_modal';

        $(elementId).modal('hide');
    }
}
