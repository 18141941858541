import Papa from 'papaparse';
import * as AbelKitWebAdmin from 'abelkit-js';
import Polygon from './Polygon';

const minNumberofColumns = 5;
const maxNumberOfcolumns = 6;

export default class MeetingpointUploader {
    constructor(map) {
        this.map = map;
    }

    parse(file) {
        return new Promise((resolve, reject) => {
            if(file == undefined || file.name.split('.').pop().toLowerCase() !=='csv') {
                throw `Please provide a valid CSV file to be uploaded.`;
            }
            Papa.parse(file, {
                complete: (results) => {
                    let data = [];
                    //Remove first line, as it contains headers
                    results.data.shift();
                    for (const [i, line] of results.data.entries()) {
                        try {
                            data.push(this.parseLine(line));
                        } catch (e) {
                            return reject(`File could not be processed as the format is wrong. Please correct the following error: "${e}" in the uploaded CSV on line: ${i+1}`);
                        }
                    }

                    let currentPolygon = null;
                    let currentMeetingpoint = null;

                    for (const line of data) {
                        if (line.polygon) {
                            if (!line.polygon.meetingpointsLoaded && line.polygon.localObject != null) {
                                // polygon has loaded meeting points
                                line.polygon.meetingpointsLoaded = true;
                            }
                            currentPolygon = line.polygon;
                        } else if (!currentPolygon) {
                            return reject('First data line should contain a polygon name');
                        }

                        if (line.meetingpoint && currentPolygon) {
                            currentPolygon.localObject.meetingpoints.push(line.meetingpoint);
                            currentMeetingpoint = line.meetingpoint;
                        }

                        if (currentMeetingpoint) {
                            currentMeetingpoint.languages.push(line.meetingpointLanguage);
                        } else {
                            return reject('First data line should contain meeting point coordinates');
                        }
                    }

                    console.log(currentPolygon);

                    this.map.redraw();

                    return resolve();
                },
                error: (err) => {
                    reject(err);
                },
                header: false,
                skipEmptyLines: true
            });
        });
    }

    parseLine(line) {
        if (line.length < minNumberofColumns || line.length>maxNumberOfcolumns)  {
            throw `Line has ${line.length} columns, must have between ${minNumberofColumns} and ${maxNumberOfcolumns}`;
        }

        let currentPolygon = null;
        let meetingpoint = null;
        let meetingpointLanguage;

        const polygonReference = line[0];
        const meetingPointCoordinates = line[1];
        const language = line[2];
        const name = line[3];
        const description = line[4];
        const url = line[5] || ' ';
        const metadata = new AbelKitWebAdmin.Objects.AKAMeetingPointMetadata({
            url: url
        });

        const notavailable = line[6] || '';

        if(meetingPointCoordinates === '') {
            throw `Please enter coordinates for the meeting point in the following format: {"x":4.904709,"y":52.385554}`;
        }

        if(polygonReference !=='') {
            if(!(currentPolygon = this.polygonExists(polygonReference))) {
                throw `Could not find polygon: ${polygonReference}. The polygon must exist before uploading meeting points.`
            }
        }


        if (meetingPointCoordinates !== '') {
            meetingpoint = new AbelKitWebAdmin.Objects.AKAMeetingPoint({
                position: JSON.parse(meetingPointCoordinates),
                languages: [],
                metadata: metadata,
                notavailable: notavailable
            });
        }

        meetingpointLanguage = new AbelKitWebAdmin.Objects.AKAMeetingPointLanguage({
            language: language,
            name: name,
            description: description
        });

        return {
            polygon: currentPolygon,
            meetingpoint: meetingpoint,
            meetingpointLanguage: meetingpointLanguage
        };
    }

    polygonExists(polygonReference) {
        for(let i=0; i < this.map.polygons.length; i++) {
            let polygon = this.map.polygons[i];
            if (polygon instanceof Polygon && polygon.localObject.reference == polygonReference) {
                return polygon;
            }
        }

        return false;
    }


}